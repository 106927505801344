import React, { useCallback, useEffect, useRef, useState } from 'react'; // TODO - item não utilizado
import { Switch, Route, withRouter, Redirect, useLocation } from 'react-router-dom'; // TODO - item não utilizado
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactGA from 'react-ga4';
import TelaPrincipal from './TelaPrincipal';

import { isAuthenticated, getMenuData } from '../services/api'; // TODO - item não utilizado
import { transformText, getAllMenuOptions, pctSaveObj, getProfileInfo } from '../services/utils'; // TODO - item não utilizado

import Login from '../container/Login';
import Profile from '../container/Profile';
import TemplateSelector from '../container/TemplateSelector';
import ContentSelector from '../container/ContentSelector';

import EditarPerfil from '../container/EditarPerfil';
import YTPlayer from '../container/YTPlayer';
import WTPlayer from '../container/WTPlayer';
import LivePlayer from '../components/LivePlayer';
import UploadTemplate from './UploadTemplate';
import UploadUser from './UploadUser';

import Messages from '../container/Messages';
import MessageFull from '../container/MessageFull';
import LojinhaHome from '../container/Lojinha/Pages/Home';
import LojinhaDetail from '../container/Lojinha/Pages/Detail';
import LojinhaCart from '../container/Lojinha/Pages/Cart';

import ChatRoom from './mini-chat/ChatRoom';
import PerfilUsusario from '../container/PerfilUsuario';
import { updatePoints, updateCoursesInProgress } from '../actions';
import LandingPage from '../container/LandingPage';
import ActivateAccount from '../container/ActivateAccount';

import { useStyles } from '../pctHooks/useThemes'; ///PTC
// import { Preloader, ScrollToTop } from "../pctComponents/atoms"; //PTC

import ContentSelectorNode from '../pctContainer/ContentSelector'; // PTC

import PctHeader from '../pctComponents/organisms/PctHeader'; // PTC
import PctFooter from '../pctComponents/organisms/PctFooter'; // PTC

import NotFound from '../pctComponents/Pages/NotFound';
import Home from '../pctComponents/Pages/Home'; // PTC
import Level from '../pctComponents/Pages/Level'; // PTC
import CardList from '../pctComponents/Pages/CardList'; // PTC
import ProfileNode from '../pctComponents/Pages/Profile'; // PCT
import MyJourney from '../pctComponents/Pages/MyJourney'; // PTC
import SearchResult from '../pctComponents/Pages/SearchResult'; // PTC
import NodeTree from 'pctComponents/Pages/NodeTree'; // PTC
import Catalog from 'pctComponents/Pages/Catalog'; // PTC
import Lms from 'pctComponents/Pages/Lms';
import ContentScorm from 'pctComponents/Pages/ContentScorm';
import Evaluations from 'pctComponents/Pages/Evaluations';
import CertificateAuthenticity from 'pctComponents/Pages/CertificateAuthenticity';
import ContactUs from 'pctComponents/Pages/ContactUs';
import Terms from 'pctComponents/Pages/Terms';

import useBackControll from "pctHooks/useBackControll";
import SupportAccess from "pctComponents/molecules/SupportAccess";
import CampaignModalManager from 'pctComponents/molecules/CampaignModalManager';
import NiceModal from '@ebay/nice-modal-react';
import ModalUpgrade from 'pctComponents/modals/ModalUpgrade';

const { setNavigationHistory } = useBackControll({});

const savePositionRoutes = [
    // "level",
    // "lms",
    // "contentNode"
];

function getAppTitle() {
    let appDetails = JSON.parse(localStorage.getItem('appDetails'));
    return appDetails?.name || 'Login';
}

const RouterContainer = (props) => {
    NiceModal.register('ModalUpgrade', ModalUpgrade);
    const menuData = localStorage.getItem('menu-data') ? JSON.parse(localStorage.getItem('menu-data')) : null;

    document.title = getAppTitle();
    let data = getAllMenuOptions(); // TODO: fazer validacao se tem dadaos

    const location = useLocation();

    ReactGA.send({ hitType: 'pageview', page: location.path });

    const notLegacyStatus = useRef(true);
    const scrollListenersSignal = useRef([]);

    if (localStorage.getItem('appDetails')) {
        const callStyles = useStyles;
        callStyles([]);
    }

    const removesSrollListeners = () => {
        scrollListenersSignal.current.map((signalController, i) => {
            signalController.abort();
        });

        return new Promise((resolve) => {
            scrollListenersSignal.current = [];
            resolve();
        });
    };

    const saveScrollPosition = (event) => {
        let top = window.scrollY;
        let left = window.scrollX;

        pctSaveObj(btoa(`pageLastPosition_${props.location.pathname}_${getProfileInfo().idUser}`), { y: top, x: left });

        event.stopPropagation();
    };

    const goToSavedScrollPosition = () => {
        let lastCurrentViewPosition = pctSaveObj(btoa(`pageLastPosition_${props.location.pathname}_${getProfileInfo().idUser}`));

        if (!lastCurrentViewPosition) {
            lastCurrentViewPosition = { y: 0, x: 0 };
        }

        const scrollContainer = window;

        scrollContainer.scrollTo({
            top: lastCurrentViewPosition.y,
            left: lastCurrentViewPosition.y,
            behavior: lastCurrentViewPosition.y === 0 ? 'instant' : 'smooth',
        });

        return new Promise((resolve) => {
            const scrollListener = (evt) => {
                if (typeof evt === 'undefined') {
                    return;
                }

                const target = evt.currentTarget;

                if (target.scrollY >= lastCurrentViewPosition.y && target.scrollX >= lastCurrentViewPosition.x) {
                    resolve();
                    scrollContainer.removeEventListener('scroll', scrollListener);
                }
            };

            scrollContainer.addEventListener('scroll', scrollListener);
        });
    };

    const reviewPosition = () => {
        const currentRouteRef = props.location.pathname.split('/')[1];

        if (savePositionRoutes.indexOf(currentRouteRef) === -1) {
            removesSrollListeners();
            window.scrollTo(0, 0);
            return;
        }

        let lastCurrentViewPosition = pctSaveObj(btoa(`pageLastPosition_${props.location.pathname}_${getProfileInfo().idUser}`));

        if (!lastCurrentViewPosition) {
            lastCurrentViewPosition = { y: 0, x: 0 };
        }

        if (document.documentElement.scrollHeight >= lastCurrentViewPosition.y) {
            goToSavedScrollPosition().then(() => {
                removesSrollListeners().then(() => {
                    const controller = new AbortController();
                    const { signal } = controller;
                    window.addEventListener('scroll', saveScrollPosition, { signal });

                    scrollListenersSignal.current.push(controller);
                });
            });
        } else {
            setTimeout(() => {
                reviewPosition();
            }, 100);
        }
    };

    useEffect(() => {
        notLegacyStatus.current = props.location.notLegacy;
        const _body = document.documentElement.classList; // HTML
        _body.toggle('peoplecraftClass', notLegacyStatus.current); // Classe do HTML que avisa que é Peoplecraft
        _body.toggle('legacyClass', !notLegacyStatus.current); // Classe do HTML que avisa que é legado

        // SALVANDO O HISTÓRICO DE NAVEGAÇÃO NO LOCALSTORAGE
        setNavigationHistory(props.location);

        // RETORNAR O SCROLL DE ONDE O USUÁRIO PAROU.
        reviewPosition();
    }, [props.location]);

    useEffect(() => {
        if (isAuthenticated()) {
            const appDetails = JSON.parse(localStorage.getItem('appDetails'));
            let favicon = document.getElementById('favicon');
            document.title = appDetails.name;
            favicon.href = appDetails.favIcon;
        }
    }, []);

    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                const _body = document.documentElement.classList;

                const canLoadComponent = _body.contains('peoplecraftClass') || _body.contains('legacyClass') || props.location.pathname == '/';
                props.location.notLegacy = rest.notLegacy ? true : false; /// PCT

                if (canLoadComponent) {
                  
                    const menuData = getMenuData();
                    if (isAuthenticated() && menuData) {
                        const { userLicenceTermsAccepted, agreementVersion, needSignResponsabilityTerms } = menuData.loginInfo;

                        if ((needSignResponsabilityTerms || userLicenceTermsAccepted !== agreementVersion) && rest.path !== '/terms') {
                            return <Redirect to="/terms" />;
                        }
                    }

                    return isAuthenticated() ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            // to={{ pathname: "/login", state: { from: props.location } }}
                            to={{
                                pathname: props.location.pathname === '/certificate-authenticity' ? '/certificate-authenticity' : '/login',
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }}
        />
    );

    return (
        <>
            {Boolean(menuData?.loginInfo?.accessSupport?.isActive) && <SupportAccess accessSupportData={menuData?.loginInfo?.accessSupport}/>}
            {menuData ? <PctHeader /> : <></>}
            {isAuthenticated() && <CampaignModalManager />}

            <TransitionGroup className="transition-group">
                <CSSTransition key={props.location.pathname} timeout={200} classNames={'fade'}>
                    <>
                        <Switch location={props.location}>
                            <Route path="/login" component={() => <Login />} />
                            <Route path="/landing-page" component={() => <LandingPage />} />
                            <Route path="/certificate-authenticity/:authenticityID" notLegacy={true} component={() => <CertificateAuthenticity />} />

                            <Route path="/activate-user" component={() => <ActivateAccount />} />
                            <PrivateRoute path="/editar-perfil" component={() => <EditarPerfil />} />
                            <PrivateRoute path="/userupload/:id" component={(props) => <UploadUser {...props} />} />
                            <PrivateRoute path="/content/:id" component={() => <ContentSelector />} />
                            <PrivateRoute path="/ytplayer/:id" component={() => <YTPlayer />} />
                            <PrivateRoute path="/wtplayer/:id" component={() => <WTPlayer />} />
                            <PrivateRoute path="/liveplayer/:id" component={() => <LivePlayer />} />
                            <PrivateRoute path="/cardMenu/:id" component={(props) => <TemplateSelector {...props} />} />
                            <PrivateRoute path="/messages/:id" component={(id) => <MessageFull id={id} />} />
                            <PrivateRoute path="/messages" component={() => <Messages />} />
                            <PrivateRoute path="/perfil" component={(props) => <Profile {...props} />} />
                            <PrivateRoute path="/lojinha" component={() => <LojinhaHome />} />
                            <PrivateRoute path="/detalheslojinha" component={() => <LojinhaDetail />} />
                            <PrivateRoute path="/cartlojinha" component={() => <LojinhaCart />} />
                            <PrivateRoute path="/uploads/userContent" component={() => <UploadTemplate />} />
                            <PrivateRoute path="/chat/:room" component={(props) => <ChatRoom {...props} />} />
                            <PrivateRoute path="/PerfilUsuario" component={(props) => <PerfilUsusario />} />
                            {data.map((item, index) => {
                                if (item.text === '') {
                                    return;
                                } // If path name is empty, return nothing
                                return (
                                    <PrivateRoute
                                        key={index}
                                        path={`/${transformText(item.text)}/${item.sectionTo}`}
                                        component={() => <TemplateSelector />}
                                    />
                                );
                            })}
                            <PrivateRoute exact path={['/blast']} component={() => <TelaPrincipal />} />
                            <PrivateRoute exact path={['/', '']} notLegacy={true} component={() => <Home />} />
                            <PrivateRoute path="/level/:id/:_nodeCode?" notLegacy={true} component={() => <Level />} />
                            <PrivateRoute path="/nodeTree/:id" notLegacy={true} component={() => <NodeTree />} />
                            <PrivateRoute
                                path="/lms/:idEnrollment/:idContentItem/:idNodeEnrollment?/:isFluidNavigation?"
                                notLegacy={true}
                                component={() => <Lms />}
                            />
                            <PrivateRoute
                                path="/evaluations/:idEnrollment?/:idEvaluation?/:idQuestionnaire?"
                                notLegacy={true}
                                component={() => <Evaluations />}
                            />
                            <PrivateRoute path="/myJourney/:tab?" notLegacy={true} component={() => <MyJourney />} />
                            <PrivateRoute exact path={['/searchResult/:searchValue', '']} notLegacy={true} component={() => <SearchResult />} />
                            <PrivateRoute
                                path="/contentNode/:id/:idNodeEnrollment?/:idEnrollment?/:idUser?/:title?/:scoType?"
                                notLegacy={true}
                                component={() => <ContentSelectorNode />}
                            />
                            <PrivateRoute
                                path="/lessons/:idEnrollment/:idContentItem/:idSco/:idNodeEnrollment?/:scoType?/:scormCompleted?/:idTeam?"
                                notLegacy={true}
                                component={() => <ContentScorm />}
                            />
                            <PrivateRoute path="/cardlist/:id" notLegacy={true} component={() => <CardList />} />
                            <PrivateRoute path="/catalog/:id/:idEnrollment?" notLegacy={true} component={() => <Catalog />} />
                            <PrivateRoute path="/editPerfil" notLegacy={true} component={(props) => <ProfileNode {...props} />} />
                            <PrivateRoute path="/contactus" notLegacy={true} component={(props) => <ContactUs {...props} />} />
                            <PrivateRoute path="/terms" notLegacy={true} component={(props) => <Terms {...props} />} />
                            <PrivateRoute path="*" notLegacy={true} component={(props) => <NotFound {...props} />} />
                        </Switch>
                    </>
                </CSSTransition>
            </TransitionGroup>

            {menuData ? <PctFooter /> : <></>}
        </>
    );
};

const mapStateToProps = (store) => ({
    footerStatus: store.footerState.footerStatus,
    headerStatus: store.footerState.headerStatus,
});

const mapDispatchToProps = (dispatch) => ({
    updatePoints: (item) => dispatch(updatePoints(item)),
    updateCoursesInProgress: (item) => dispatch(updateCoursesInProgress(item)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RouterContainer);