import React from 'react';

import { Like } from 'pctComponents/atoms';
import { ContentNavigationBtn, ContentTreeMap } from 'pctComponents/molecules';

function HeaderContentNavigation({ configHeaderFooter, scoSingleContent }) {
    return configHeaderFooter.contentNavigation && configHeaderFooter?.contentNavigation?.questionnaireType !== 'PRE_TEST' ? (
        <div className="contentNavigation">
            {configHeaderFooter.contentNavigation.content || scoSingleContent ? (
                <Like
                    direction="invert"
                    collection={{
                        data: scoSingleContent ? scoSingleContent : configHeaderFooter.contentNavigation.content,
                        elementType: 'CONTENT',
                        idElement: scoSingleContent ? scoSingleContent.idContentItem : configHeaderFooter.contentNavigation.content.idContentItem,
                    }}
                    className="likeNav"
                />
            ) : (
                <></>
            )}

            <ContentNavigationBtn direction="prev" text="Anterior" collection={configHeaderFooter.contentNavigation} />

            <ContentNavigationBtn
                direction="next"
                text="Próximo"
                unlockNext={configHeaderFooter.unlockNext}
                collection={configHeaderFooter.contentNavigation}
            />

            {/* BOTÃO PARA ACESSAR O MAPA DE CONTEÚDOS */}
            <ContentTreeMap collection={configHeaderFooter.contentNavigation} />
        </div>
    ) : (
        <></>
    );
}

export default HeaderContentNavigation;
