import './style.scss';
import React, { useState, useRef } from 'react';
import { GrFormNextLink } from 'react-icons/gr';
import useSrcSetVerify from '../../../pctHooks/useSrcSetVerify';
import useMediaQuery from '../../../pctHooks/useMediaQuery';
import SVG from 'react-inlinesvg';

import { connect } from 'react-redux';
import { updatePreTest } from 'actions';

import { Text, Label, Button, Image, Rate, ProgressBar, Duration, Visualization, ButtonCertificate, Like, ViewSyllabus } from '../../atoms';
import useCards from 'pctHooks/useCards';

type Props = {
    className?: string;
    data: any;
    level?: boolean;
    btnInfo?: string;
    type?: string;
    _parentData?: any;
    size?: any;
    elemType?: string | null;
    elemId?: any;
    configPreTest?: {
        statusPreTest: Boolean | null;
    };
};

const Banner = (props: Props) => {
    const { data, type = 'level', className = '', _parentData, size = 'l', elemType = null, elemId = null } = props;

    const setProgressStateRef = useRef(null as any);
    const progressInfoRef = useRef(null as any);

    let btnInfo = '';

    let collection: any = { data: data, elementType: '', idElement: elemId };
    let elementType = collection.data.idNode ? 'NODE' : 'CONTENT';

    elementType = elemType ? elemType : elementType;

    if (data.hasOwnProperty('contentEnrollment')) {
        collection.idNodeEnrollment = data.contentEnrollment.idNodeEnrollment
            ? data.contentEnrollment.idNodeEnrollment
            : _parentData.node.nodeEnrollment.idNodeEnrollment;
        collection.idEnrollment = data?.contentEnrollment.enrollment.idEnrollment;
    }

    collection.elementType = elementType;

    const {
        image,
        concluidedText,
        isEnrollment,
        canEnrollment,
        noTeamAvaliable,
        initialProgressState,
        statusRegistered,
        handleCardMouseEvent,
        handleCertificateMouseEvent,
        hasCertificate,
        statusElement,
        verifyCompletedStatus,
    } = useCards({
        collection: collection,
        dataParent: _parentData,
        setProgressStateRef,
        progressInfoRef,
    });

    const [progressInfo, setProgressState] = useState(initialProgressState());

    const configPreTest = props.configPreTest?.statusPreTest || false;

    setProgressStateRef.current = setProgressState;
    progressInfoRef.current = progressInfo;

    const { rate, label, title, description, workload, views, entryPoint } = data;

    if (type === 'level' && entryPoint != null) {
        //AQUI PRECISA SER CRIADA A LÓGICA DE MATRICULA OU DE INICIAR O TREINAMENTO.
        if (isEnrollment()) {
            //Acessa o treinamento, pois já possí matricula ativa.
            btnInfo = 'Iniciar';
            // pathLink = `/level/{${idEnrollment}`;
        } else if (canEnrollment()) {
            //Apresenta o quadro para escolher uma turma.
            btnInfo = 'Matricular-se';
        } else if (noTeamAvaliable()) {
            //Realiza a auto matricula
            btnInfo = 'Matricular-se';
        }
    } else if (collection.elementType === 'CONTENT') {
        btnInfo = 'Iniciar';
    }

    // const currentMobileImage = mobileImage() ? mobileImage() : image();

    const tabletStatus = useMediaQuery('lg');
    const mobileStatus = useMediaQuery('sm');
    const sizeConvert = (format) => {
        const foSize = {
            l: 'landscape-large',
            m: 'landscape-middle',
            s: 'landscape-small',
        };

        return foSize[format] ?? 'landscape-large';
    };

    const formatSize = mobileStatus ? 'portrait' : tabletStatus ? 'landscape-large' : sizeConvert(size);
    const srcSetVerify = useSrcSetVerify(image(), formatSize);

    const handleCard = (e: React.MouseEvent<HTMLElement>) => {
        if (configPreTest) {
            e.stopPropagation();
            return;
        }

        handleCardMouseEvent(e);
    };

    const handleCertificate = (e: React.MouseEvent<HTMLElement>) => {
        if (configPreTest) {
            e.stopPropagation();
            return;
        }

        handleCertificateMouseEvent(e);
    };

    return (
        <div className={`pctMolecule pctBanner ${className}`} data-aspectratio={formatSize}>
            {image && (
                <>
                    <div className="containerImg">
                        {collection.data.link !== '' && collection.data.link !== null && (
                            <a href={collection.data.link} target="_blank">
                                {collection.data.image && (
                                    <>
                                        <Image className="imgBanner" path={image()} pathSet={srcSetVerify} />
                                    </>
                                )}

                                {!collection.data.image && <div className="thumb">{image() && <SVG src={image()} />}</div>}
                            </a>
                        )}

                        {(collection.data.link === '' || collection.data.link === null) && (
                            <>
                                {collection.data.image && (
                                    <>
                                        <Image className="imgBanner" path={image()} pathSet={srcSetVerify} />
                                    </>
                                )}

                                {!collection.data.image && <div className="thumb">{image() && <SVG src={image()} />}</div>}
                            </>
                        )}

                        <div className="imgEffect"></div>
                    </div>
                </>
            )}
            {elementType !== 'IMAGE' && (
                <div className="banner-container">
                    <div className="banner-container-base">
                        {rate && <Rate text="Avalie" />}
                        <div className="contaLabel">
                            {label && <Label text={label} />}
                            <ViewSyllabus collection={collection} />
                            <Like
                                // direction="invert"
                                collection={{
                                    ...collection,
                                    idElement: elemId,
                                    elementType: elemType,
                                }}
                                disabled={!!configPreTest}
                                statusRegistered={statusRegistered()}
                                className="likeSmall"
                            />
                        </div>

                        {title && <Text component="h1" text={title} />}

                        {description && type === 'level' && <Text component="div" text={description} className={'description'} />}

                        {type !== 'IMAGE' && (
                            <div className="containerBtns">
                                {btnInfo !== '' && <Button ico={GrFormNextLink} text={btnInfo} callEvent={handleCard} className="button01" />}
                            </div>
                        )}

                        {type !== 'level' && (
                            <div className="progressInfo">
                                {progressInfo > 0 && <ProgressBar percentage={progressInfo} status={statusElement()} />}

                                <div className="certificateProgress">
                                    <Text
                                        component="small"
                                        className={`textProgress ${progressInfo < 100 ? 'progress' : 'finished'}`}
                                        text={concluidedText()}
                                    />
                                    {hasCertificate() && (
                                        <ButtonCertificate verifyCompletedStatus={verifyCompletedStatus()} onClick={handleCertificate} />
                                    )}
                                </div>
                            </div>
                        )}

                        {workload > 0 && <div className="containerInfo">{workload ? <Duration text={workload} timeFormat={'seconds'} /> : null}</div>}

                        {views > 0 && <div className="containerInfo">{views >= 0 ? <Visualization text={views} /> : null}</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (store) => ({
    configPreTest: store.configPreTestState,
});

const mapDispatchToProps = (dispatch) => ({
    updatePreTest: (item) => dispatch(updatePreTest(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
