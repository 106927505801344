import { useEffect, useRef } from 'react'

import { getLevelNodeEnrollment } from "services/api";

import {
  getProfileInfo,
} from "services/utils";



const useNotificationCampaigns = () => {
   
    const idUser = getProfileInfo()?.idUser;
    const notiCampaignsRef = useRef<any>(null);
   
    const verifyCompleteCampaign = (dataNodeContentFinish) => {

        const nodeCampaing: any =  notiCampaignsRef.current.node;
        const props: any =  notiCampaignsRef.current.props;

        //console.log("antigo Status "+nodeCampaing.nodeEnrollment.status, "novo Status "+dataNodeContentFinish.nodeEnrollment.status )

        ///Verifica se a matricula, ao entrar no conteúdo, o status NÃO é: COMPLETED e PASSED 
        if (
            nodeCampaing &&
            nodeCampaing.nodeEnrollment.status != "COMPLETED" && 
            nodeCampaing.nodeEnrollment.status != "PASSED"
        ) {
            ///Verifica se a matricula é COMPLETED ou PASSED, depois de completar o conteúdo em que esta. 
            if(dataNodeContentFinish.nodeEnrollment.status === "COMPLETED" || 
                dataNodeContentFinish.nodeEnrollment.status === "PASSED" ){
                //console.log('complete ou passed', props);
                props.updateConfigCampaign({earnPointsStatus:true})
            }
        }
        //reset
        notiCampaignsRef.current.node = null;
    };

    const responseNotificationCampaigns  = ( response, props ) => {
      if (response.data.node.hasCampaign) {
        if (!notiCampaignsRef.current){
          // console.log('verificação do status na entrada nodeEnrollment: ', response.data.node, props)
          props.updateConfigCampaign({earnPointsStatus:false})
          notiCampaignsRef.current = {props: props , node: response.data.node };
        }else{
          verifyCompleteCampaign(response.data.node);
        }
      }
    }
    
    
    const verifyNotificationCampaigns  = ( contentEnrollment, props ) => {
      if ( contentEnrollment) {
        const { idEnrollment } = contentEnrollment;

        //Se vc estiver vindo do level - os dados vão vir do redux
        if( props?.apiReplication?.getLevelNodeEnrollment){
          const response = props?.apiReplication?.getLevelNodeEnrollment;
          responseNotificationCampaigns(response, props);
        }else{
          getLevelNodeEnrollment(idUser, idEnrollment, null).then((response) => {    
            responseNotificationCampaigns(response, props);
          });
        }
      }   
    }

    useEffect(()=>{
      return()=>{
        if(notiCampaignsRef.current) {
          notiCampaignsRef.current.props.updateConfigCampaign({earnPointsStatus:false});
          notiCampaignsRef.current.props.updateApiReplication({ getLevelNodeEnrollment: null });
        } 
        
      }
    },[])
    
    return {
      verifyNotificationCampaigns
    }
 
}

export default useNotificationCampaigns

