import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { AiOutlineDownload } from 'react-icons/ai';
import { updateHeaderStatus, updateFooterStatus, updateConfigHeaderFooter, updateConfigCampaign, updateApiReplication } from '../../../actions';

import { learningContentsTracking, recordTracking, learningContentUpdate } from '../../../services/api';

import useCards from 'pctHooks/useCards';
import { Award, Duration, Image, Label, Star, Text, Visualization } from 'pctComponents/atoms';

import useNotificationCampaigns from '../../../pctHooks/useNotificationCampaigns';

const ContentFile = (props) => {
    // const isPdf = (contentLocation) => {
    //   return contentLocation.split(".").pop() === "pdf";
    // };

    const { data, dataParentObj, idSection } = props;
    const { image } = data;
    const setProgressStateRef = useRef(null as any);
    const progressInfoRef = useRef(null as any);
    const concluidedText = useRef('');
    const [updateStatus, setUpdateStatus] = useState(false);

    let tempData = data;
    tempData.contentEnrollment = dataParentObj.contentEnrollment;

    tempData = {
        data: tempData,
        contentEnrollment: dataParentObj.contentEnrollment,
    };

    const { verifyNotificationCampaigns } = useNotificationCampaigns();

    const { callDownload } = useCards({
        collection: tempData,
        dataParent: tempData,
        setProgressStateRef,
        progressInfoRef,
    });

    const _recordTracking = async (_type: string | null = null) => {
        if (props.data.idContentEnrollment) return false;

        // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
        const timeStart = 0;
        const navItem = props.data.idContentItem;
        const navType = 1;
        const idSection = props.data.idSection;
        const timeExecuted = 0;

        // console.log(timeStart, navItem, navType, idSection, timeExecuted);
        const res = await recordTracking(timeStart, navItem, navType, idSection, timeExecuted);
    };

    const contentTracking = async () => {
        if (props.data.idContentEnrollment && props.dataParentObj.contentEnrollment.status !== 'COMPLETED') {
            if (props.data.idContentEnrollment) {
                const objTrackingContent = {
                    status: 'COMPLETED',
                    grade: 0,
                    progress: 100,
                };

                await learningContentUpdate(objTrackingContent, props.data.idContentEnrollment);
            }

            if (props.params.idEnrollment) {
                const objTrackingContent = {
                    idEnrollment: props.params.idEnrollment,
                    type: 'ACCESS_CONTENT',
                    progress: 100,
                };

                await learningContentsTracking(objTrackingContent, props.data.idContentItem);
            }

            /// Verifica ao completar o Content e se o NodeErrolment foi Completado e Notificação Campaingns
            const contentNavigation = { idEnrollment: props.dataParentObj.currentItem.idEnrollment };
            verifyNotificationCampaigns(contentNavigation, props);
        }

        const { updateConfigHeaderFooter } = props;

        updateConfigHeaderFooter({
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: dataParentObj,
            backCloseText: 'FECHAR CONTEÚDO',
            unlockNext: true,
        });
    };

    const callContendDownload = async () => {
        callDownload(() => {
            contentTracking();
        });

        concluidedText.current = `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}`;
        setUpdateStatus(true);

        _recordTracking();
    };

    useEffect(() => {
        props.updateConfigHeaderFooter({
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: dataParentObj,
            backCloseText: 'FECHAR CONTEÚDO',
        });

        verifyNotificationCampaigns({ idEnrollment: dataParentObj.currentItem.idEnrollment }, props); // Verificar status Notification Campaigns Inicial
    }, []);

    if (dataParentObj.contentEnrollment && !concluidedText.current) {
        concluidedText.current =
            dataParentObj.contentEnrollment.status === 'COMPLETED' ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}` : '';
    } else if (props.data.hasOwnProperty('completed')) {
        concluidedText.current = props.data.completed == 1 ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}` : '';
    }

    return (
        <div className={`pctPage fileDetailPct`}>
            <div className="contentHeader">
                <Image path={image} />
            </div>

            <div className="infos">
                <div className="infosBase">
                    {(data.text || data.description) && (
                        <div className="mediaInfos">
                            {data.text && <Text component="h2" text={data.text} />}
                            {data.description && <Text component="p" text={data.description} />}
                        </div>
                    )}

                    <div className="footerInfos">
                        <div className="leftInfo">
                            <div className="dataInfos">
                                <div className="dis dis1">
                                    {/* {<Visualization text={data.visits ? data.visits : 0} />} */}

                                    {/* {(data.name)&&<Label text={ data.name } className="label"/>} */}

                                    {<Label text={data.label ? data.label : 'Arquivo'} className="label" />}

                                    {!!data.duration && <Duration text={data.duration} timeFormat={'seconds'} />}

                                    {/* <a
                        href={data.contentLocation}
                        download
                        target="_blank"
                      > */}
                                    <button onClick={() => callContendDownload()} className="btnDownload">
                                        <AiOutlineDownload />
                                        <Text component="p" text={'Baixar arquivo'} />
                                    </button>
                                    {/* </a> */}

                                    <Text component="small" text={concluidedText.current} className="concluidedText" />
                                </div>
                            </div>
                        </div>

                        {/* {(data.star || data.award) && (
              <div className="rightInfo">
                <div className="baseInfo">
                  {data.star && <Star text={data.star} />}
                  {data.award && <Award text={data.award} />}
                </div>
              </div>
            )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
    updateApiReplication: (item) => dispatch(updateApiReplication(item)),
});

const mapStateToProps = (store) => ({
    apiReplication: store.apiReplicationState,
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
    configCampaign: store.configCampaignState.configCampaign,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentFile);
