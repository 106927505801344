import React, { useEffect } from "react";
import { updateFooterStatus, updateHeaderStatus } from "../actions";
import { connect } from "react-redux";
import { getDesignById, getFooterSize, getColors, getDesign } from "../services/utils";
import Rate from "./Rate";
import { getToken } from "../services/api";
import '../assets/css/CardGrid.scss';
import { useHistory } from "react-router-dom";
import Conclusion from '../assets/images/Icone conclusion.svg'

const token = getToken();

function ContentSite({ data, idSection, updateHeader, updateFooter }) {
  const history = useHistory();
  let isDesktop = window.innerWidth > 800 ? true : false;

  useEffect(() => {
    updateFooter(getDesign(idSection, "general")?.showBottomMenu || "true");
    updateHeader(getDesign(idSection, "general")?.showTopBar || "true");
    if (!Boolean(data.contentLocation) && !Boolean(data.content)) {
      history.goBack()
    }
  }, []);
  
  return (
    
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...{ height: `calc(100vh - 54px)` },
        ...{ backgroundColor: data.backgroundColor },
        ...getFooterSize(),
      }}
    >
      
      <div
        className="content-site-rating"
        style={{
          backgroundColor: getColors("menu").fourthColor,
          padding: isDesktop ? "0  70px" : "10px 14px",
          justifyContent: 'space-between' ,
          flexWrap:'wrap'

        }}
      >
       {data.progress ? 
        (
          <div className="size-porcentagem-header">         
            <div style={{display:'flex', backgroundColor:getColors("menu").firstColor}}>                      
              <div className={"card-progress"} style={{width:`${data.progress}%`}}/>
            </div>
          </div>
        ) : null
        }
        <Rate 
        id={data.idContentItem} 
        stars={data.rating} 
        rateContainer= {{backgroundColor:"#fff"}}
         styleFeedBack={{color:'#fff'}}
          numberVotes={data.numberVotes} 
          avalie ={'avalie este conteúdo'} 
          click={true}
          classNameContainer={"heigth-rate-in-content-site"}
           />
      </div>
      <iframe
        src={`${data.contentLocation}?token=${token}`}
        height="100%"
        width="100%"
        frameBorder="0"
        allowFullScreen
      />
      
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateFooter: (item) => dispatch(updateFooterStatus(item)),
  updateHeader: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(ContentSite);
