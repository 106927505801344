import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { BiError } from 'react-icons/bi';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { compose } from 'redux';

import Loader from '../components/Loader';
import { updateConfigHeaderFooter, updatePlan } from '../actions';
import usePlan from '../pctHooks/usePlan';

import { appDetails, getUserLoginInfo, loginSSO, logout, logoutSSO, renewlogin, sendEmailPassword, setLoginData, userLogin } from '../services/api';

import { getAppDetails } from '../services/utils';

import { QueryClient } from '@tanstack/react-query';

import '../themes/import.scss';

// Crie uma instância do QueryClient
const queryClient = new QueryClient();
class Login extends React.Component {
    _isMounted = true;
    _loadFirst = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isDesktop: false,
            login: '',
            password: '',
            redirect: false,
            errorText: '',
            isLoading: false,
            forgotPassword: false,
            newAccess: false,
            isLoadingForgot: false,
            emailforgot: '',
            forgotMessage: '',
            appType: 1,
            appDetails: { response: null },
            loadingApp: true,
            loadingAppError: false,
            loginSelected: false,
            passwordSelected: false,
            lastTracking: {},
            redirectLandingPage: false,
            redirectActivateUser: false,
            loginChecked: false,
            token: null,
            userLoginInfo: null,
            automaticLogin: false,
        };

        this.resize = this.resize.bind(this);
    }

    async login(urlToken = null) {
        return new Promise((resolve) => {
            const loginSuccess = (res) => {
                if (res.data.success === false) {
                    this.setState({
                        errorText: 'Senha ou usuário inválido.',
                        isLoading: false,
                    });
                }
                setLoginData(res.data);

                this.setState({
                    redirect: true,
                    lastTracking: res.data.lastTracking[0] || {},
                });

                this.updatePlanLogin();

                resolve(true);
            };

            const loginError = (err) => {
                this.setState({
                    errorText: 'Senha ou usuário inválido.',
                    isLoading: false,
                });
                resolve(true);
            };

            if (urlToken) {
                // this.setState({ isLoading: true, errorText: "" });
                renewlogin(urlToken)
                    .then((res) => loginSuccess(res))
                    .catch((err) => console.log(err));

                return;
            }

            this.setState({ isLoading: true, errorText: '' });

            if (!this.state.loginChecked) {
                getUserLoginInfo(this.state.login, this.state.appDetails.idApp, this.state.appDetails.companyLogin)
                    .then(({ data }) => {
                        this.setState({
                            isLoading: false,
                            loginChecked: true,
                            userLoginInfo: data,
                        });
                        resolve(true);
                    })
                    .catch((err) => {
                        this.setState({
                            errorText: 'Usuário inválido.',
                            isLoading: false,
                        });
                        resolve(true);
                    });

                return;
            }

            userLogin(
                this.state.login,
                this.state.password,
                this.state.appDetails.idAppType,
                this.state.appDetails.idApp,
                this.state.appDetails.companyLogin,
            )
                .then((res) => loginSuccess(res))
                .catch((err) => loginError(err));
        });
    }

    _setPwaAppIcons(data) {
        let dynamicManifest = {
            name: data.name,
            short_name: data.name,
            description: data.name,
            start_url: '.',
            theme_color: '#000000',
            background_color: '#ffffff',
            display: 'standalone',
            prefer_related_applications: false,
            icons: [
                {
                    src: data.logo,
                    sizes: '192x192',
                    type: 'image/png',
                },
                {
                    src: data.logo,
                    sizes: '256x256',
                    type: 'image/png',
                },
                {
                    src: data.logo,
                    sizes: '512x512',
                    type: 'image/png',
                },
            ],
        };
        const stringManifest = JSON.stringify(dynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
    }

    _checkAndExecuteSAMLAutoRedirect() {
        if (
            !this.hasByPassSSO() &&
            !this.hasTokenParam() &&
            this.state.appDetails.hasSamlConfiguration &&
            this.state.appDetails.samlConfiguration?.autoRedirect
        ) {
            loginSSO(this.state.appDetails.bucket, this.state.appDetails.idApp);
            return;
        }
        this.setState({ loadingApp: false });
    }

    async _appDetails() {
        this.setState({ loadingAppError: false });
        let favicon = document.getElementById('favicon');
        const bucketUrl = window.location.host;
        const staticUrl = process.env.REACT_APP_STATIC_URL;
        // console.log(staticUrl);
        // smartapp.digital
        // homolog.bedigital.net.br
        // kryptosapp.com
        const currentUrl = process.env.NODE_ENV === 'development' ? staticUrl : bucketUrl;
        const path = (window.location.pathname ?? '').substring(1).split('/')[0];

        // Usando queryClient para fazer a consulta manualmente
        queryClient
            .fetchQuery({
                queryKey: ['appDetails', currentUrl], // A chave da consulta
                queryFn: () => appDetails(currentUrl), // A função que busca os dados
            })
            .then((result) => {
                if (result.data.app.length === 0) {
                    this.setState({ loadingAppError: true });
                    return;
                }

                this._setPwaAppIcons(result.data.app[0]);
                localStorage.setItem('appDetails', JSON.stringify(result.data.app[0]));
                document.title = result.data.app[0].name;
                favicon.href = result.data.app[0].favIcon;
                if (this._isMounted) {
                    this.setState({ appDetails: result.data.app[0] }, this._checkAndExecuteSAMLAutoRedirect);
                }
            })
            .catch((error) => {
                this.setState({ loadingAppError: true, loadingApp: false });
            });

        // if (this._isMounted && this.state.appDetails.response === null) {
        //     try {
        //         const result = await appDetails(currentUrl, path);

        //         if (result.data.app.length === 0) {
        //             this.setState({ loadingAppError: true });
        //             return;
        //         }
        //         this._setPwaAppIcons(result.data.app[0]);
        //         localStorage.setItem('appDetails', JSON.stringify(result.data.app[0]));
        //         document.title = result.data.app[0].name;
        //         favicon.href = result.data.app[0].favIcon;
        //         this.setState({ appDetails: result.data.app[0] }, this._checkAndExecuteSAMLAutoRedirect);
        //     } catch (err) {
        //         this.setState({ loadingAppError: true, loadingApp: false });
        //     }
        // }
    }

    async _redirectLogout() {
        return new Promise((resolve) => {
            const samlLogoutUrl = getAppDetails().samlConfiguration?.logoutUrl;

            logout();

            if (samlLogoutUrl) {
                logoutSSO(samlLogoutUrl);
            }

            localStorage.setItem(
                'navigationHistory',
                JSON.stringify({
                    history: ['/'],
                    redirectedFrom: null,
                    isfluidNavigation: null,
                    lastPage: null,
                    currentPage: null,
                }),
            );

            resolve(true);
        });
    }

    async componentDidMount() {
        const url = window.location.href.split('?token=');
        const urlToken = url[1] || null;
        const savedToken = localStorage.getItem('menu-data') ? JSON.parse(localStorage.getItem('menu-data')).token : null;
        const redirectToken = localStorage.getItem('redirectToken') || null;
        const isAutomaticLogin = () => {
            return (urlToken || redirectToken) && !this.state.login && !this.state.password;
        };

        this.props.updateConfigHeaderFooter({ footerStyle: 'hide' });
        this._appDetails();
        this.resize();
        window.addEventListener('resize', this.resize);

        if (isAutomaticLogin()) {
            if (!redirectToken) {
                localStorage.setItem('redirectToken', urlToken);
                await this._redirectLogout();
                await this.login(urlToken);
                this.setState({ automaticLogin: true, token: urlToken });
                this.props.history.push('/');
            } else {
                await this.login(redirectToken);
                this.setState({ token: redirectToken, automaticLogin: true });
                this.props.history.push('/');
            }
        } else if (savedToken) {
            await this.login(savedToken);
            this.setState({ token: savedToken, automaticLogin: true });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        this.setState({ isDesktop: window.innerWidth > 900 });
    }

    sendMail() {
        this.setState({ isLoadingForgot: true });
        sendEmailPassword({ username: this.state.emailforgot, idAppType: this.state.appDetails?.idAppType })
            .then((res) => {
                if (res.data.success === false) {
                    toast.error('Erro ao enviar e-mail!', { position: 'top-center' });
                    this.setState({
                        isLoadingForgot: false,
                        forgotMessage: res.data.message,
                    });
                }
                this.setState({
                    isLoadingForgot: false,
                    forgotMessage: res.data.message,
                    forgotPassword: false,
                });
                toast.success('E-mail enviado com sucesso!', {
                    position: 'top-center',
                });
            })
            .catch((err) => {
                toast.error('Erro ao enviar e-mail!', { position: 'top-center' });
                this.setState({
                    isLoadingForgot: false,
                    forgotMessage: 'Erro ao enviar e-mail.',
                });
            });
    }

    _getFormPosition() {
        const appDetails = this.state.appDetails;
        switch (appDetails?.loginPosition) {
            case 'top':
                return 'flex-start';
            case 'middle':
                return 'center';
            case 'bottom':
                return 'flex-end';
            default:
                return 'center';
        }
    }

    isFromLandingPage() {
        const pathParams = new URLSearchParams(this.props.location.search);
        return !!pathParams.get('fromLP');
    }

    hasByPassSSO() {
        const pathParams = new URLSearchParams(this.props.location.search);
        return pathParams.get('byPassSSO') === 'true';
    }

    hasTokenParam() {
        const pathParams = new URLSearchParams(this.props.location.search);
        return !!pathParams.get('token');
    }

    handleFirstAccess() {
        const idAppType = this.state.appDetails?.idAppType;
        if (idAppType === 2) {
            this.setState({ redirectActivateUser: true });
        } else {
            this.setState({ forgotPassword: true, newAccess: true });
        }
    }

    async updatePlanLogin() {
        const date = usePlan().getData();
        this.props.updatePlan(date);
    }

    render() {
        let pathnameRedirect = '/';
        let lastRedirect = false;
        const navigationHistoryStorage = JSON.parse(localStorage.getItem('navigationHistory'));

        if (navigationHistoryStorage && navigationHistoryStorage.history && navigationHistoryStorage.history.length > 0) {
            const lastHistory = navigationHistoryStorage.history[navigationHistoryStorage.history.length - 1];
            pathnameRedirect = lastHistory !== '/' && lastHistory !== '' ? lastHistory : '/';
            lastRedirect = lastHistory !== '/' && lastHistory !== '' ? true : false;
        }

        if (this.state.redirect && !this.hasTokenParam() && !localStorage.getItem('menu-data')) {
            return (
                <Redirect
                    to={{
                        pathname: pathnameRedirect,
                        state: {
                            referrer: 'login',
                            lastTracking: this.state.lastTracking,
                            lastContentRedirect:
                                this.state.appDetails?.lastContentRedirect === 1 || this.state.appDetails?.lastContentRedirect === 'true',
                        },
                    }}
                />
            );
        }

        if (this.state.redirectActivateUser) {
            return <Redirect to={{ pathname: '/activate-user' }} />;
        }

        const url = window.location.href.split('?token=');
        const urlToken = url[1] || null;

        if (
            !this.state.token &&
            this.state.appDetails.landingPage === 1 &&
            !this.isFromLandingPage() &&
            !this.hasByPassSSO() &&
            !lastRedirect &&
            urlToken === null &&
            !localStorage.getItem('menu-data') &&
            !localStorage.getItem('redirectToken')
        ) {
            return <Redirect to={{ pathname: '/landing-page' }} />;
        } else if (localStorage.getItem('menu-data') && urlToken === null) {
            return <Redirect to={{ pathname: '/' }} />;
        }

        const { isDesktop } = this.state;

        return (
            <>
                {this.state.loadingApp || this.state.automaticLogin ? (
                    <div className="tela-login-container" style={{ alignItems: 'center' }}>
                        <Loader
                            customStyle={{ height: 100 }}
                            message={
                                this.state.automaticLogin
                                    ? 'Aguarde, você será redirecionado para a aplicação...'
                                    : 'Aguarde, carregando a aplicação...'
                            }
                        />
                    </div>
                ) : (
                    <div className="login-main-container">
                        <div className="login-form-wrapper">
                            {this.state.forgotPassword ? (
                                <div
                                    className="tela-login-container tela-login-container-revi"
                                    style={{
                                        // alignItems: this._getFormPosition(),
                                        backgroundImage: `url(${
                                            !isDesktop
                                                ? this.state.appDetails?.loginImageMobile
                                                    ? this.state.appDetails?.loginImageMobile
                                                    : this.state.appDetails?.loginImage
                                                : 'none'
                                        })`,
                                    }}
                                >
                                    <div
                                        className="logo-login"
                                        style={{
                                            // alignItems: this._getFormPosition(),
                                            backgroundImage: `url(${this.state.appDetails?.logo})`,
                                        }}
                                    ></div>
                                    <form className={`login-wrapper ${!isDesktop && 'login-blur'}`} style={{ width: isDesktop ? '70%' : '80%' }}>
                                        <div
                                            className=""
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                marginBottom: 30,
                                            }}
                                        >
                                            <h4>{this.state.newAccess ? 'Novo por aqui?' : 'Esqueceu sua senha?'}</h4>
                                            <span>
                                                Por favor, insira seu login para receber o link
                                                {this.state.newAccess ? ' de primeiro acesso.' : ' para alterar os seus dados.'}
                                            </span>
                                        </div>
                                        <div>
                                            <TextField
                                                label="Login"
                                                inputProps={{
                                                    autoComplete: 'off',
                                                    autoCapitalize: 'off',
                                                    autoCorrect: 'off',
                                                    spellCheck: 'off',
                                                }}
                                                style={{ width: '100%', marginBottom: 10 }}
                                                onKeyDown={(e) => e.key === 'Enter' && this.sendMail()}
                                                onChange={(e) => this.setState({ emailforgot: e.target.value })}
                                            />
                                        </div>
                                        {/* <br /> */}
                                        <div className="forgot-buttons" style={{ marginTop: 30 }}>
                                            <button
                                                className="btn btn-secondary btn-voltar"
                                                style={{
                                                    width: '40%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    height: 45,
                                                    alignItems: 'center',
                                                }}
                                                type="button"
                                                onClick={() =>
                                                    this.setState({
                                                        forgotPassword: false,
                                                        newAccess: false,
                                                    })
                                                }
                                            >
                                                <strong>VOLTAR</strong>
                                            </button>
                                            <button
                                                className="btn botao-login"
                                                style={{
                                                    width: '55%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    height: 45,
                                                }}
                                                type="button"
                                                onClick={() => {
                                                    this.sendMail();
                                                }}
                                            >
                                                <strong>{this.state.isLoadingForgot ? 'ENVIANDO' : 'ENVIAR E-MAIL'}</strong>
                                                {this.state.isLoadingForgot && (
                                                    <div className="loader" style={{ position: 'static', marginLeft: 10 }} />
                                                )}
                                            </button>
                                        </div>
                                        {!this.state.forgotPassword && (
                                            <div className="login-error-container">
                                                <span className="login-forgot">{this.state.forgotMessage}</span>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            ) : (
                                <div
                                    className="tela-login-container"
                                    style={{
                                        // alignItems: this._getFormPosition(),
                                        backgroundImage: `url(${
                                            !isDesktop
                                                ? this.state.appDetails?.loginImageMobile
                                                    ? this.state.appDetails?.loginImageMobile
                                                    : this.state.appDetails?.loginImage
                                                : 'none'
                                        })`,
                                    }}
                                >
                                    {this.state.loadingAppError ? (
                                        <div className="tela-login-loading bucket-error">
                                            <BiError size={50} color={'indianred'} />
                                            <span>Nenhuma aplicação correspondente a URL</span>
                                        </div>
                                    ) : (
                                        <div className="login-wrapper-container">
                                            {this.state.appDetails?.showLoginLogo !== 0 && (
                                                <img className="login-img-icon" src={this.state.appDetails?.logo} alt="login-logo" />
                                            )}
                                            <div
                                                className="logo-login"
                                                style={{
                                                    // alignItems: this._getFormPosition(),
                                                    backgroundImage: `url(${this.state.appDetails?.logo})`,
                                                }}
                                            ></div>

                                            <form
                                                className="login-wrapper"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <>
                                                    {!this.hasTokenParam() && !localStorage.getItem('redirectToken') ? (
                                                        <TextField
                                                            id="standard-basic"
                                                            label="Login"
                                                            autoFocus={true}
                                                            className={`${this.state.loginChecked ? 'loginChecked' : ''}`}
                                                            style={{ width: '100%', marginBottom: 10 }}
                                                            required
                                                            onKeyDown={(e) => e.key === 'Enter' && this.login()}
                                                            onBlur={(e) => this.setState({ loginSelected: false })}
                                                            onSelect={(e) => e.type === 'select' && this.setState({ loginSelected: true })}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    login: e.target.value,
                                                                    loginChecked: false,
                                                                })
                                                            }
                                                            InputLabelProps={{
                                                                style: {
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    width: '100%',
                                                                    color: '#363636',
                                                                    fontFamily: "'Open Sans', sans-serif",
                                                                    height: 25,
                                                                    fontWeight: 18,
                                                                    top: -5,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    WebkitBoxShadow: `0 0 0 1000px ${
                                                                        this.state.loginSelected || this.state.login ? 'transparent' : 'transparent'
                                                                    } inset`,
                                                                },
                                                                autoComplete: 'off',
                                                                autoCapitalize: 'off',
                                                                autoCorrect: 'off',
                                                                spellCheck: 'off',
                                                            }}
                                                        />
                                                    ) : (
                                                        <>Aguarde, você está sendo redirecionado...</>
                                                    )}

                                                    {this.state.loginChecked && (
                                                        <TextField
                                                            id="standard-basic-password"
                                                            type="password"
                                                            style={{ width: '100%', marginTop: 10 }}
                                                            label="Senha"
                                                            required
                                                            inputRef={(input) => input && this.state.loginChecked && input.focus()}
                                                            onChange={(e) => this.setState({ password: e.target.value })}
                                                            onKeyDown={(e) => e.key === 'Enter' && this.login()}
                                                            onBlur={() => this.setState({ passwordSelected: false })}
                                                            onSelect={(e) => e.type === 'select' && this.setState({ passwordSelected: true })}
                                                            InputLabelProps={{
                                                                style: {
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    width: '100%',
                                                                    color: '#363636',
                                                                    fontFamily: "'Open Sans', sans-serif",
                                                                    height: 25,
                                                                    fontWeight: 18,
                                                                    top: -5,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    WebkitBoxShadow: `0 0 0 1000px ${
                                                                        this.state.passwordSelected || this.state.login
                                                                            ? 'transparent'
                                                                            : 'transparent'
                                                                    } inset`,
                                                                },
                                                                autoComplete: 'off',
                                                            }}
                                                        />
                                                    )}
                                                </>

                                                <div className="d-flex align-items-baseline justify-content-between">
                                                    {!this.hasTokenParam() && !localStorage.getItem('redirectToken') && (
                                                        <button
                                                            className="btn botao-login"
                                                            type="button"
                                                            style={{ marginTop: 30 }}
                                                            onClick={(e) => {
                                                                this.login();
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <>{this.state.loginChecked ? 'Fazer login' : 'Avançar'}</>
                                                            {this.state.isLoading && (
                                                                <div style={{ marginLeft: 10 }}>
                                                                    <div className="loader" style={{ position: 'relative', right: 0 }} />
                                                                </div>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                                {this.state.forgotPassword && (
                                                    <div className="login-forgot-container">
                                                        <span
                                                            onClick={() =>
                                                                this.setState({
                                                                    forgotPassword: true,
                                                                    newAccess: false,
                                                                })
                                                            }
                                                            className="login-forgot"
                                                            style={{ cursor: 'pointer', color: '#363636' }}
                                                        >
                                                            Esqueci minha senha
                                                        </span>
                                                    </div>
                                                )}

                                                {!this.hasTokenParam() && !localStorage.getItem('redirectToken') && (
                                                    <div className="login-forgot-container card-footer-login-options">
                                                        <span
                                                            className="login-forgot"
                                                            style={{ cursor: 'pointer', color: '#363636' }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    forgotPassword: true,
                                                                    newAccess: false,
                                                                })
                                                            }
                                                        >
                                                            Esqueceu sua senha?
                                                        </span>
                                                        <span
                                                            className="login-forgot"
                                                            style={{ cursor: 'pointer', color: '#363636' }}
                                                            onClick={() => this.handleFirstAccess()}
                                                        >
                                                            Primeiro acesso?
                                                        </span>
                                                    </div>
                                                )}

                                                {this.state.loginChecked &&
                                                    this.state.userLoginInfo &&
                                                    this.state.userLoginInfo.hasSamlConfiguration && (
                                                        <div className="mt-3">
                                                            <span
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: '#363636',
                                                                    fontSize: 14,
                                                                }}
                                                                onClick={() => {
                                                                    loginSSO(
                                                                        this.state.appDetails.bucket,
                                                                        this.state.userLoginInfo.idApp,
                                                                        this.state.userLoginInfo.idCompany,
                                                                    );
                                                                }}
                                                            >
                                                                Entrar com credenciais da minha empresa
                                                            </span>
                                                        </div>
                                                    )}
                                                {this.state.errorText && (
                                                    <div className="login-error-container" style={{ marginTop: 10 }}>
                                                        <span className="login-error-text"> {this.state.errorText} </span>
                                                    </div>
                                                )}
                                            </form>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="login-image-wrapper">
                            <div
                                className="tela-login-container"
                                style={{
                                    backgroundImage: `url(${
                                        !isDesktop && this.state.appDetails?.loginImageMobile
                                            ? this.state.appDetails?.loginImageMobile
                                            : this.state.appDetails?.loginImage
                                    })`,
                                    // alignItems: this._getFormPosition(),
                                }}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updatePlan: (item) => dispatch(updatePlan(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Login);
