import './style.scss';

import React, { useState, useEffect } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Tooltip, withStyles } from '@material-ui/core';

import { useModal } from '@ebay/nice-modal-react';

import { FaEllipsisVertical } from 'react-icons/fa6';
import { TiDocument } from 'react-icons/ti';
import { RiCalendarEventFill } from 'react-icons/ri';
import { BiComment } from 'react-icons/bi';
import GroupIcon from '@material-ui/icons/Group';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ModalSyllabus from 'pctComponents/modals/ModalSyllabus';

import { removeTagsHtml } from 'services/utils';
import { InteractionDrawer } from 'pctComponents/organisms';
import { DrawerContainer, DrawerContent, DrawerTabs, EntityTypeEnum } from 'pctComponents/organisms/InteractionDrawer/InteractionDrawer.types';
import useMediaQuery from 'pctHooks/useMediaQuery';
import { FiPaperclip } from 'react-icons/fi';
import AttachmentSideBar from 'pctComponents/organisms/AttachmentSideBar';

type Props = { nodeCollection: BlockElement };
type collectionProps = { data: DataBlockElement };

const MenuHeaderContent = ({ nodeCollection }: Props) => {
    const isMobile = useMediaQuery('md');
    const userModal = useModal(ModalSyllabus);
    const [collection, setCollection] = useState<collectionProps | null>(null);
    const [drawerContainer, setDawerContainer] = useState<DrawerContainer>({} as DrawerContainer);
    const [singleLevelContent, setSingleLevelContent] = useState(false);
    const [isOpenComments, setIsOpen] = useState<boolean>(false);
    const [showInteractionDrawerButton, setShowInteractionDrawerButton] = useState(false);
    const [isOpenAttachmentSideBar, setIsOpenAttachmentSideBar] = useState<boolean>(false);
    const [attachmentStatus, setAttachmentStatus] = useState<any>(null);

    const CustomTooltip = withStyles((theme) => ({
        tooltip: {
            fontSize: 8,
            maxWidth: 300,
            background: '#211F26',
        },
    }))(Tooltip);

    const createTabLabel = (_isMobile, icon, text, notificationCount = null) => (
        <div className="interaction-tab-label">
            {notificationCount !== null ? (
                <div className="interaction-tab-label-enrolled-Badge">
                    {icon}
                    {/* <span className="interaction-tab-notification">{notificationCount}</span> */}
                </div>
            ) : (
                icon
            )}
            {!_isMobile && text}
        </div>
    );

    const CreateDrawerContainer = (node: DataBlockElement, team: Team) => {
        const allowPageComments = Boolean(node?.allowComments);
        const allowTeamComments = Boolean(node?.nodeEnrollment?.team?.allowComments);
        const tabs: DrawerTabs[] = [];
        const contents: DrawerContent[] = [];

        const pageCommentsLabel = Boolean(node.commentsLabel) ? node.commentsLabel : 'Interações e comentários';
        const teamCommentsLabel = Boolean(node?.nodeEnrollment?.team?.commentsLabel)
            ? node?.nodeEnrollment?.team?.commentsLabel
            : 'Interações e comentários na minha turma';

        if (allowPageComments) {
            tabs.push({
                id: 0,
                title: pageCommentsLabel,
                icon: <ChatBubbleOutlineIcon className="interaction-tab-icon" />,
                label: createTabLabel(isMobile, <ChatBubbleOutlineIcon className="interaction-tab-icon" />, pageCommentsLabel),
            });
            contents.push({
                title: pageCommentsLabel,
                entityType: EntityTypeEnum.NODE,
                idEntity: `${node.nodeCode}`,
            });
        }

        if (allowTeamComments) {
            tabs.push({
                id: 1,
                title: teamCommentsLabel,
                icon: <GroupIcon className="interaction-tab-icon" />,
                label: createTabLabel(isMobile, <GroupIcon className="interaction-tab-icon" />, teamCommentsLabel),
            });
            contents.push({
                title: teamCommentsLabel,
                entityType: EntityTypeEnum.TEAM,
                idEntity: `${team.idTeam}`,
            });
        }

        return {
            showInteractionDrawer: allowPageComments || allowTeamComments,
            drawerContainer: {
                tabs,
                contents,
                enabledTabs: tabs.length >= 2,
            },
        };
    };

    useEffect(() => {
        if (nodeCollection && nodeCollection.node) {
            const { showInteractionDrawer, drawerContainer } = CreateDrawerContainer(
                nodeCollection.node,
                nodeCollection.node?.nodeEnrollment?.team as Team,
            );
            setShowInteractionDrawerButton(showInteractionDrawer);
            setDawerContainer(drawerContainer);
            setCollection({ data: nodeCollection.node });
            setSingleLevelContent(nodeCollection.levelSingle ? true : false); // Verifica se ocorreu um redirecionamento do level para um conteúdo

            setAttachmentStatus(nodeCollection?.node?.nodeEnrollment?.team?.hasAttachments);
        }

        return () => {
            setAttachmentStatus(null);
            setShowInteractionDrawerButton(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeCollection, isMobile]);

    const callSyllabus = (e) => {
        e.stopPropagation();
        userModal.show({ collection });
    };

    const syllabusStatus =
        nodeCollection &&
        collection &&
        collection.data.syllabus &&
        removeTagsHtml(collection.data.syllabus).length > 0 &&
        collection.data.nodeType === 'LEARNING'
            ? true
            : false;
    const meetingStatus = false;

    const verifyStatus = !singleLevelContent && (syllabusStatus || meetingStatus || Boolean(showInteractionDrawerButton) || attachmentStatus);

    const MenuHeaderContentDesk = () => {
        return (
            <div className="menuHeaderContentDesk">
                {syllabusStatus && (
                    <CustomTooltip title={'Ver ementa'} placement="bottom">
                        <button onClick={callSyllabus}>
                            <TiDocument />
                        </button>
                    </CustomTooltip>
                )}
                {meetingStatus && (
                    <CustomTooltip title={'Encontros'} placement="bottom">
                        <button>
                            <RiCalendarEventFill />
                        </button>
                    </CustomTooltip>
                )}
                {attachmentStatus && nodeCollection?.node && (
                    <CustomTooltip title={'Anexos de turma'} placement="bottom" onClick={() => setIsOpenAttachmentSideBar(true)}>
                        <button>
                            <FiPaperclip />
                        </button>
                    </CustomTooltip>
                )}
                {Boolean(showInteractionDrawerButton) && (
                    <>
                        <CustomTooltip title={'Interações e comentários'} placement="bottom">
                            <button className="commentBtn" onClick={() => setIsOpen(true)}>
                                <div className="commentB">
                                    <BiComment />
                                </div>
                            </button>
                        </CustomTooltip>
                    </>
                )}
            </div>
        );
    };

    const MenuHeaderContentMobile = () => {
        return (
            <div className="menuHeaderContentMobile">
                <DropdownMenu.Root
                    onOpenChange={(e) => {
                        // setDrownOpen(e);
                    }}
                >
                    <DropdownMenu.Trigger className="DropdownMenuTriggerHeaderContent" asChild>
                        <button className={`perfilUsuarioHeader`}>
                            <FaEllipsisVertical />
                        </button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Portal className="DropdownMenuTriggerHeaderPortal">
                        <DropdownMenu.Content className="dropdownMenuContentHeaderPortal" sideOffset={3}>
                            {syllabusStatus && (
                                <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={callSyllabus}>
                                    <TiDocument />
                                    <span>Ver ementa</span>
                                </DropdownMenu.Item>
                            )}

                            {attachmentStatus && nodeCollection?.node && (
                                <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={() => setIsOpenAttachmentSideBar(true)}>
                                    <div className="commentB">
                                        <FiPaperclip />
                                    </div>
                                    <span>Anexos de turma</span>
                                </DropdownMenu.Item>
                            )}

                            {meetingStatus && (
                                <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={() => {}}>
                                    <RiCalendarEventFill />
                                    <span>Encontros</span>
                                </DropdownMenu.Item>
                            )}

                            {Boolean(showInteractionDrawerButton) && (
                                <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={() => setIsOpen(true)}>
                                    <div className="commentB">
                                        <BiComment />
                                        {/* <div className="comment">05</div> */}
                                    </div>
                                    <span>Interações e comentários</span>
                                </DropdownMenu.Item>
                            )}
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </div>
        );
    };

    return verifyStatus ? (
        <div className="menuHeaderContent">
            <MenuHeaderContentDesk />
            <MenuHeaderContentMobile />
            {isOpenComments && <InteractionDrawer {...drawerContainer} isOpen={isOpenComments} handleClose={() => setIsOpen(false)} />}
            {attachmentStatus && nodeCollection?.node && (
                <AttachmentSideBar
                    nodeCollection={nodeCollection.node as DataBlockElement}
                    isOpen={isOpenAttachmentSideBar}
                    handleClose={() => setIsOpenAttachmentSideBar(false)}
                />
            )}
        </div>
    ) : null;
};

export default MenuHeaderContent;
