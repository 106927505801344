import "./style.scss";

import React, { useState, useEffect, Dispatch } from "react";

import { Text } from "pctComponents/atoms";
import { PlaceholderStandard } from "pctComponents/placehoader";
import { CardStandard, CarrousselView } from "pctComponents/molecules";
import { getSearchCatalog } from "services/api";
import { CardGridCatalog } from "pctComponents/organisms";

import useCardsFormat from "pctHooks/useCardsFormat";
import useMediaQuery from "pctHooks/useMediaQuery";

type Props = {
  catalogData: Block;
  searchString: string;
  tags: Array<string>;
  elementAll: number;
  setElementAll: Dispatch<number>;
  openGrid: Boolean;
  setOpenGrid: Dispatch<boolean>;
};

const CatalogListing = ({
  catalogData,
  searchString,
  tags,
  elementAll,
  setElementAll,
  openGrid,
  setOpenGrid,
}: Props) => {
  const collectionRef: Block = catalogData;
  const { parameters } = collectionRef;
  const { depth, nodeCode } = parameters;

  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState<any[]>([]);
  const [indiceOffset, setIndiceOffset] = useState(0);
  const [indiceOffsetMobile, setIndiceOffsetMobile] = useState(false);
  const [completeloading, setCompleteLoading] = useState<Boolean | null>(null);
  
  const smStatus = useMediaQuery("sm"); ///mobile
  const mdStatus = useMediaQuery("md");
  const lgStatus = useMediaQuery("lg");
  const mvStatus = useMediaQuery("mv");

  const flktyReady = (flkty, e) => {
    flkty.select(indiceOffset);
  };

  const flktyChange = (flkty, indice) => {
    if (indiceOffset < indice && elements.length <= elementAll) {
      if (smStatus) {
        if (indice % 5 === 0) createMap(indice);
      } else {
        if (indice % 2 === 0) createMap(indice);
      }
    }
  };

  const tagsToAPIFormat = (arr: any) => {
    let arrRt: Array<string> = [];

    arr.map((item: any, i) => {
      arrRt.push(item.name);

      return item;
    });

    return arrRt;
  };

  const { displayColumnsElementsCalc, formatCard, effectCard } = useCardsFormat(
    {
      cardFormat: parameters.cardFormat,
    }
  );

  ///Calculo da dimensão da largura de cada card
  let colunsElements = displayColumnsElementsCalc
    ? displayColumnsElementsCalc
    : 5;
  // let colunsElements = 4;
  const widthCard = `${100 / colunsElements}%`;
  const widthCardStyle = { width: widthCard };

  ///Controle do offset e do limit da fn getSearchCatalog
  /// O offset é o indice do carrossel (indice * nu de itens por slide + (plus) para add o avançar em uma nova tela)
  /// O limit é o nu de itens por slide * 2 | no indice 0 é inclementado um (plus)
  /// O plus tem que ter um valor de no min 1 elemento para que sempre exista o avançar
  // 2 elem

  if (mdStatus) colunsElements = 2;
  if (lgStatus) colunsElements = 3;

  // 4 ou 5 elem
  // if (mvStatus)
  //   colunsElements = displayColumnsElements ? displayColumnsElements : 5;

  // const colunsPlaceholder = smStatus ? 1 : colunsElements;

  // let params = {};
  // const offsetBase = indiceOffset * colunsElements;
  // const plusOffsetInit = 1; // esse valor atendeu de 2 ate 5 card
  // params = {
  //   depth: depth ? depth : 0,
  //   offset: indiceOffset !== 0 ? offsetBase + plusOffsetInit : 0,
  //   limit:
  //     indiceOffset !== 0
  //       ? colunsElements * 2
  //       : colunsElements * 2 + plusOffsetInit,
  // };

  // /// Versao de 1 elemento
  // if (smStatus) {
  //   params["offset"] = indiceOffset !== 0 ? indiceOffset + 1 : 0;
  //   params["limit"] = indiceOffset !== 0 ? 5 : 6;
  // }

  const paramsBase = (ind = 0) => {
    let params = {};

    const offsetBase = ind * colunsElements;
    const plusOffsetInit = 1; // esse valor atendeu de 2 ate 5 card
    params = {
      depth: depth ? depth : 0,
      offset: ind !== 0 ? offsetBase + plusOffsetInit : 0,
      limit:
        ind !== 0 ? colunsElements * 2 : colunsElements * 2 + plusOffsetInit,
    };

    /// Versao de 1 elemento
    if (smStatus) {
      params["offset"] = ind !== 0 ? ind + 1 : 0;
      params["limit"] = ind !== 0 ? 5 : 6;
    }

    if (searchString) {
      params["searchString"] = searchString;
    }
    if (tags.length > 0) {
      params["tags"] = tagsToAPIFormat(tags);
    }

    return params;
  };

  useEffect(() => {
    createMap(0, !!searchString || (tags && tags.length > 0));
  }, [searchString, tags]);

  const createMap = async (ind, searchInit = false) => {
    setLoading(true);
    setIndiceOffset(ind);

    let params = paramsBase(ind);

    try {
      const res = await getSearchCatalog(nodeCode, params);

      if (res.data.totalRecords) setElementAll(res.data.totalRecords);

      if (elements.length < res.data.totalRecords)
        setElements((prev) => [...prev, ...res.data.data]);

      if (searchInit) {
        setElements([...res.data.data]);
      }

      // if (res.data.data.length === 0) setElements([]);
      if (res.data.data.length === 0) setElements((prev) => [...prev]);

      setLoading(false);
      setCompleteLoading(true);
    } catch (err) {
      console.log(err);
    }
  };

  const CreateItem = ({ collection }) => {
    let convertToBlockElement = {} as BlockElement;
    convertToBlockElement.data = collection;
    convertToBlockElement.data.parentType = "CATALOG";
    convertToBlockElement.elementType = "NODE";

    return (
      <CardStandard
        css={widthCardStyle}
        collection={convertToBlockElement}
        format={formatCard}
        effects={effectCard}
      />
    );
  };

  if (loading && !openGrid)
    return (
      <PlaceholderStandard
        number={colunsElements}
        css={widthCardStyle}
        format={formatCard}
      />
    );

  if (!loading && elements.length === 0)
    return (
      <Text
        component="h3"
        text={"Não foram encontrados resultados"}
        className="notSearch"
      />
    );

  return (
    <>
      <CarrousselView
        flktyReady={(flkty, e) => flktyReady(flkty, e)}
        flktyChange={(flkty, e) => flktyChange(flkty, e)}
        options={{ selectedAttraction: 1, friction: 1 }}
      >
        {elements.map((item, i) => {
          return <CreateItem collection={item} key={i} />;
        })}
      </CarrousselView>

      {openGrid && (
        <CardGridCatalog
          setOpenGrid={setOpenGrid}
          flktyChange={flktyChange}
          elements={elements}
          CreateItem={CreateItem}
          widthCardStyle={widthCardStyle}
          loading={loading}
          indiceOffsetMobile={indiceOffsetMobile}
          elementAll={elementAll}
          format={formatCard}
          completeloading={completeloading}
          setCompleteLoading={setCompleteLoading}
          colunsElements={colunsElements}
          openGrid={openGrid}
        />
      )}
    </>
  );
};

export default CatalogListing;
