import React from 'react';
import './style.scss';

import { Button, Text, Image, ProgressBar } from 'pctComponents/atoms';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import useSrcSetVerify from '../../../pctHooks/useSrcSetVerify';
import SVG from 'react-inlinesvg';

import { PreTestSVG } from '../../../pctComponents/svg';

type Props = {
    dataPreTest: any;
    handleClose: (e) => void;
    handleEvaluation: (e) => void;
};

export const ModalPreTest = NiceModal.create(({ dataPreTest, handleClose, handleEvaluation }: Props) => {
    const modal = useModal();
    const { collection, image, initialProgressState, statusElement } = dataPreTest;

    const format = 'landscape-xsmall';
    const imgBase = image().image ? image().image : image();
    const srcSetVerify = useSrcSetVerify(imgBase, format);

    const closed = (e) => {
        handleClose(e);
        modal.remove();
    };

    const callEvaluation = (e) => {
        handleEvaluation(e);
        modal.remove();
    };

    return (
        <div id="modalPreTest" className="modalPreTest">
            <div className="modal-body-pct">
                <div className="modal-container-base">
                    <div className="thumbContainer">
                        <div className="thumb" data-aspectratio={format}>
                            {collection.data.image ? <Image path={image()} pathSet={srcSetVerify} /> : <SVG src={PreTestSVG()} />}
                        </div>
                    </div>
                    <div className="infosContainer">
                        <Text component="h4" text={'<span>Pré-teste:</span> ' + collection.data.title} />
                        <Text component="p" text={'Para acessar este conteúdo, é necessário realizar o pré-teste de conhecimento obrigatório.'} />
                        <div className="progressConatainer">
                            <ProgressBar percentage={initialProgressState()} status={statusElement()} />
                            <div className="statusBar">
                                <Text component="small" text={initialProgressState() === 0 ? 'Não iniciado' : 'Em andamento'} />
                            </div>
                        </div>
                        <div className="bottonBtn">
                            <Button text="Agora não" className="button01" callEvent={(e) => closed(e)} />
                            <Button text="Iniciar pré-teste" className="button03" callEvent={(e) => callEvaluation(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ModalPreTest;
