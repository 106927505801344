import React from 'react';

import useInfiniteScroll from 'pctHooks/useInfiniteScroll';

import { getAttachmentsByIdTeam } from 'services/api';
import { acceptFormats } from './attachment';

import { Text } from '../../atoms';

import Icons from './icons';

import './style.scss';

const ListItens = ({ nodeCollection }) => {
    const { items, loading, containerRef } = useInfiniteScroll({
        limit: 25,
        fetchItems: getAttachmentsByIdTeam,
        params: nodeCollection.nodeEnrollment?.team?.idTeam ? { idTeam: nodeCollection.nodeEnrollment?.team?.idTeam } : null,
    });

    const getTypeNameByFormat = (format) => {
        let result = { name: '' };
        acceptFormats.forEach((type) => {
            if (type.acceptFormats.split(',').includes('.' + format.toLowerCase())) {
                result = type;
            }
        });

        return result.name;
    };

    // console.log(loading);

    return (
        <div ref={containerRef} className="listItens">
            {items &&
                items.length > 0 &&
                items.map((it, indice) => {
                    const type = getTypeNameByFormat(it.format);
                    return (
                        <a
                            target="_blank"
                            key={indice}
                            href={it.url}
                            style={{
                                borderBottom: 'solid 1px var(--light-80-color)',
                                marginLeft: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                                display: 'block',
                                color: 'var(--light-80-color)',
                            }}
                        >
                            <Icons icon={type} /> {type} - {it.title}
                        </a>
                    );
                })}
        </div>
    );
};

export default ListItens;
