import './style.scss';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Label, Text, Image, Like } from '../../atoms';
import useSrcSetVerify from '../../../pctHooks/useSrcSetVerify';
import useCards from '../../../pctHooks/useCards';
import { useParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { connect } from 'react-redux';
import { updatePreTest } from 'actions';

type Props = {
    className?: string;
    css?: object;
    collection: any;
    dataParent: any;
    type?: string;
    format: string;
    effects: string;
    configPreTest?: {
        statusPreTest: Boolean | null;
    };
};

type idType = {
    id?: string;
};

const CardNavigation = (props: Props) => {
    const { collection, dataParent, className = '', css = {}, format = 'portrait', effects = '' } = props;

    const { id } = useParams<idType>();
    const setProgressStateRef = useRef(null as any);
    const progressInfoRef = useRef(null as any);

    const currentCollection = collection.data ? collection.data : collection;
    const { nodeCode, title, description, label, idElement, parameters } = currentCollection;
    const history = useHistory();

    const configPreTest = props.configPreTest?.statusPreTest || false;

    const {
        image,
        concluidedText,
        verifyCompletedStatus,
        currentLabel,
        isEnrollment,
        canEnrollment,
        noTeamAvaliable,
        initialProgressState,
        evaluationEnrollment,
        statusRegistered,
        prerequisitesMet,
        routerLink,
        handleCardMouseEvent,
        handleCertificateMouseEvent,
        hasCertificate,
        currentBreadText,
        statusElement,
        hasEnrollment,
    } = useCards({
        collection,
        id,
        dataParent,
        setProgressStateRef,
        progressInfoRef,
    });

    // const handleMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
    //   const currentElementType = parameters
    //     ? parameters.elementType.toUpperCase()
    //     : collection.elementType
    //     ? collection.elementType.toUpperCase()
    //     : "NODE";
    //   let pathLink = "/";

    //   if (currentElementType === "NODE") {
    //     pathLink = `/catalog/${nodeCode}`;
    //   } else if (currentElementType === "CONTENT") {
    //     pathLink = `/contentNode/${idElement}/'-'`;
    //   }

    //   history.push(`${pathLink}`);
    // };

    const imgBase = statusElement() === 'ARCHIVED' && image().image ? image().image : image();
    const srcSetVerify = useSrcSetVerify(imgBase, format);

    const handleCard = (e: React.MouseEvent<HTMLElement>) => {
        if (configPreTest) {
            e.stopPropagation();
            return;
        }

        handleCardMouseEvent(e);
    };

    return (
        <div className="pctMolecule pctCard pctCardNavigation" style={css} onClick={handleCard}>
            {currentCollection.image && (
                <div className="containerData" data-aspectratio={format}>
                    <Like direction="invert" collection={collection} statusRegistered={statusRegistered()} />

                    <Image path={image()} pathSet={srcSetVerify} cardEffect={effects === 'shadow'} />
                </div>
            )}

            {!currentCollection.image && (
                <div className="containerData" data-aspectratio={format}>
                    <Like direction="invert" collection={collection} statusRegistered={statusRegistered()} />

                    <div className="thumb">{image() && <SVG src={image()} />}</div>
                </div>
            )}

            {title && <Text component="h4" text={title} className="titleCard" />}
            {description && <Text component="p" text={description} className="description" />}
            {label && <Label text={label} className={'label'} />}
        </div>
    );
};

const mapStateToProps = (store) => ({
    configPreTest: store.configPreTestState,
});

const mapDispatchToProps = (dispatch) => ({
    updatePreTest: (item) => dispatch(updatePreTest(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardNavigation);
