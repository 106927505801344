import './style.scss';
import React from 'react';
// import CompleteRating from "../CompleteRating";
import 'themes/import.scss';
import 'assets/css/ModalPlayer.scss';

import { updateConfigHeaderFooter, updateConfigCampaign, updateApiReplication } from '../../../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { recordTracking, learningContentsTracking, learningContentUpdate } from 'services/api';
import ModalDialog from '../../modals/ModalDialog';
import IdleTimer from 'react-idle-timer';
import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Award, Duration, Image, Label, Star, Text } from 'pctComponents/atoms';
import AudioPlayer from 'react-h5-audio-player';

import useNotificationCampaigns from '../../../pctHooks/useNotificationCampaigns';

class ContentAudio extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.idleTimerRef = null;

        this.state = {
            timeRefresh: 300000, // 300000ms = 5min
            isOpen: true,
            key: 0,
            descriptiion: '',
            image: '',
            contentLocation: '',
            url: null,
            playing: false,
            controls: false,
            played: 0,
            duration: 0,
            isDesktop: false,
            idContentItem: 0,
            timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            isLoadingData: true,
            noData: false,
            lastTime: 0,
            arrayTime: [],
            timeExecuted: 0,
            modal: false,
            shouldRecordTracking: true,
            concluidedText: '',
            mediaPercentage: 0,
            completedAudio: false,
        };
    }

    handleOnActive() {
        this.setState({ ignoreRecordTracking: false });
    }

    handleOnIdle() {
        if (this.state.playing && this.idleTimerRef) {
            this.idleTimerRef.reset();
        } else {
            this.exit();
        }
    }

    resetTime() {
        if (this.idleTimerRef) {
            this.idleTimerRef.reset();
        }
    }

    startFromBeggining() {
        // this.player.seek(this.props.data.lastPosition);
        this.player.audio.current.currentTime = 0;
        this.setState({ modal: false });
    }

    continueFromLastTime() {
        this.setState({ modal: false, playing: true });
    }

    currentTime = () => {
        // return this.player?.getCurrentTime()
        return this.player.audio.current.currentTime;
    };

    handlePause = () => {
        this.setState({ playing: false });
    };

    handlePlay = () => {
        this.setState({ playing: true });
        this.trackStart(this);
    };

    componentDidMount() {
        this.setState({ shouldRecordTracking: true });
        if (!(this.props.data.lastPosition === null || this.props.data.lastPosition === 0) && !this.props.data.idContentEnrollment) {
            this.player.audio.current.currentTime = this.props.data.lastPosition;
            this.setState({ modal: true });
        }

        const { updateConfigHeaderFooter, verifyNotificationCampaigns } = this.props;

        updateConfigHeaderFooter({
            prevNavStyle: 'closeShow',
            contentNavigation: this.props.dataParentObj,
            footerStyle: 'hide',
            backCloseText: 'FECHAR CONTEÚDO',
        });

        const contentNavigation = this.props.dataParentObj.contentEnrollment;
        verifyNotificationCampaigns(contentNavigation, this.props); /// Verifica ao entrar Notficação Campaingns

        if (this.props.dataParentObj.contentEnrollment) {
            const concluidedTextInfo =
                this.props.dataParentObj.contentEnrollment.status === 'COMPLETED'
                    ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}`
                    : '';
            this.setState({ completedAudio: true });
            this.setState({ concluidedText: concluidedTextInfo });
        } else if (this.props.data.hasOwnProperty('completed')) {
            const concluidedTextInfo = this.props.data.completed == 1 ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}` : '';

            this.setState({ completedAudio: true });
            this.setState({ concluidedText: concluidedTextInfo });
        }
    }

    onEnd = async () => {
        const concluidedTextInfo = "<i class='fa fa-check' aria-hidden='true'></i> Concluído";
        this.setState({ concluidedText: concluidedTextInfo });
        this.setState({ completedAudio: true });

        if (this.props.data.idContentEnrollment && this.props.dataParentObj.contentEnrollment.status !== 'COMPLETED') {
            if (this.props.data.idContentEnrollment) {
                const objTrackingContent = {
                    status: 'COMPLETED',
                    grade: 0,
                    progress: 100,
                };

                await learningContentUpdate(objTrackingContent, this.props.data.idContentEnrollment);
            }

            if (this.props.params.idEnrollment) {
                const objTrackingContent = {
                    idEnrollment: this.props.params.idEnrollment,
                    type: 'ACCESS_CONTENT',
                    progress: 100,
                };

                await learningContentsTracking(objTrackingContent, this.props.data.idContentItem);
            }

            /// Verifica ao completar o Content e se o NodeErrolment foi Completado e Notificação Campaingns
            const contentNavigation = this.props.dataParentObj.contentEnrollment;
            this.props.verifyNotificationCampaigns(contentNavigation, this.props);
        }

        const { updateConfigHeaderFooter } = this.props;

        updateConfigHeaderFooter({
            prevNavStyle: 'closeShow',
            contentNavigation: this.props.dataParentObj,
            footerStyle: 'hide',
            unlockNext: true,
            backCloseText: 'FECHAR CONTEÚDO',
        });

        this._recordTracking();
    };

    onListen = async (e) => {
        const percentage = 100 - Math.round(((e.target.duration - e.target.currentTime) / e.target.duration) * 100);
        this.setState({ mediaPercentage: percentage });
    };

    _recordTracking = async (_type = null) => {
        if (this.props.data.idContentEnrollment) return false;

        const timeStart = this.state.timeStart;
        const navItem = this.props.data.idContentItem;
        const navType = 1;
        const idSection = this.props.idSection || this.props.data.idSection;
        const timeExecuted = Math.floor(this.currentTime());

        // console.log(timeStart, navItem, navType, idSection, timeExecuted);

        await recordTracking(timeStart, navItem, navType, idSection, timeExecuted);
    };

    componentWillUnmount() {
        if (this.props.dataParentObj.contentEnrollment) {
            this.exit();
        } else {
            this._recordTracking('WillUnmount');
        }
    }

    exit = async () => {
        if (
            this.props.data.idContentEnrollment &&
            this.props.dataParentObj.contentEnrollment.status !== 'COMPLETED' &&
            this.state.mediaPercentage > this.props.dataParentObj.contentEnrollment.progress &&
            this.state.mediaPercentage < 100 &&
            !this.state.completedAudio
        ) {
            const objTrackingContent = {
                status: 'IN_PROGRESS',
                grade: 0,
                progress: this.state.mediaPercentage,
            };

            console.log('audio in_progress novamente', this.props, this.state.mediaPercentage);
            await learningContentUpdate(objTrackingContent, this.props.data.idContentEnrollment);
        }
    };

    ref = (player) => {
        this.player = player;
    };

    trackStart() {
        this.setState({ timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss') });
    }

    render() {
        const { playing, isDesktop } = this.state;
        const { data } = this.props;

        return (
            <div className="player-audio-wrapper">
                <ModalDialog
                    btn1Info={{
                        event: () => {
                            this.startFromBeggining();
                        },
                        text: 'Ouvir desde o inicío?',
                    }}
                    btn2Info={{
                        event: () => {
                            this.continueFromLastTime();
                        },
                        text: 'Continuar de onde parou?',
                        className: 'active',
                    }}
                    show={this.state.modal}
                />

                <IdleTimer
                    ref={(ref) => {
                        this.idleTimerRef = ref;
                    }}
                    timeout={this.state.timeRefresh}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    debounce={250}
                />

                <div className="contentHeader">
                    <Image path={data.image} />
                </div>

                <div className="infos">
                    <div className="infosBase">
                        {(data.text || data.description) && (
                            <div className="mediaInfos">
                                {data.text && <Text component="h2" text={data.text} />}
                                {data.description && <Text component="p" text={data.description} />}
                            </div>
                        )}

                        <div className="footerInfos">
                            <div className="rightInfo">
                                <div className="baseInfo">
                                    <div className="mediaPlayer">
                                        <AudioPlayer
                                            ref={this.ref}
                                            src={data.contentLocation}
                                            // onPlaying={ playing }
                                            onPlay={() => this.handlePlay()}
                                            onPause={() => this.handlePause()}
                                            onEnded={() => this.onEnd()}
                                            onListen={(e) => this.onListen(e)}
                                        />

                                        {/* {data.star && <Star text={data.star} />}
                      {data.award && <Award text={data.award} />} */}
                                    </div>
                                </div>
                            </div>
                            <div className="leftInfo">
                                <div className="dataInfos">
                                    <div className="dis dis1">
                                        {/* {<Visualization text={data.visits ? data.visits : 0} />} */}

                                        {/* {(data.name)&&<Label text={ data.name } className="label"/>} */}

                                        {<Label text={data.label ? data.label : 'Áudio'} className="label" />}

                                        {data.duration && <Duration text={data.duration} timeFormat={'seconds'} />}

                                        <Text component="small" text={this.state.concluidedText} className="concluidedText" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
    updateApiReplication: (item) => dispatch(updateApiReplication(item)),
});

const mapStateToProps = (store) => ({
    apiReplication: store.apiReplicationState,
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
    configCampaign: store.configCampaignState.configCampaign,
});

export const withRouterWrapper = (WrappedComponent) => (props) => {
    const params = useParams();

    const { verifyNotificationCampaigns } = useNotificationCampaigns();

    return <WrappedComponent {...props} params={params} verifyNotificationCampaigns={verifyNotificationCampaigns} />;
};

export default compose(withRouterWrapper, connect(mapStateToProps, mapDispatchToProps))(ContentAudio);
