import './style.scss';

import React from 'react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { chatLoadRoom, getToken, learningContentsTracking, learningContentUpdate, recordTracking } from '../../../services/api';
import { getDesignById, getColors } from '../../../services/utils';

import ModalDialog from '../../modals/ModalDialog';

import { updateConfigHeaderFooter, updateConfigCampaign, updateApiReplication } from '../../../actions';

import { Duration, Label, Text } from '../../atoms';
import ShareButton from 'pctComponents/organisms/ShareButton';

import useNotificationCampaigns from '../../../pctHooks/useNotificationCampaigns';

class ContentVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            showChat: false,
            messages: [],
            newMessage: '',
            isDesktop: false,
            hasChat: false,
            controls: true,
            idContentItem: 0,
            timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            arrayTime: [],
            modal: false,
            timeElapsed: 0,
            lastPosition: 0,
            allowSaving: false,
            playing: false,
            concluidedText: '',
            mediaPercentage: 0,
        };

        this.resize = this.resize.bind(this);
        this.videoPlay = React.createRef();
        this.startedTime = this.props.data.timeExecuted;
    }

    retrieveVideoData() {
        const lastPosition = this.props.data.lastPosition;
        if (!(lastPosition === null || lastPosition === 0) && !this.props.data.idContentEnrollment) {
            this.setState({ modal: true });
        } else {
            this.setState({ allowSaving: true });
        }
    }

    start() {
        this.setState({ modal: false });
    }
    beginVideo() {
        this.setState(
            {
                timeElapsed: 0,
                allowSaving: true,
                playing: true,
            },
            () => {
                this.videoPlay.current.seekTo(0, 'seconds');
            },
        );
    }
    prepareVideo(time) {
        this.setState(
            {
                timeElapsed: time,
                allowSaving: true,
                playing: true,
            },
            () => {
                this.videoPlay.current.seekTo(time, 'seconds');
            },
        );
    }

    updateExecutedTime = (time) => {
        if (this.state.allowSaving) {
            this.setState({ timeElapsed: time });
            this.startedTime = time;
        }
    };

    async componentDidMount() {
        this.setState({
            timeElapsed: this.props.data.timeExecuted,
        });
        this.retrieveVideoData();

        this.resize();
        window.addEventListener('resize', this.resize);
        this.setState({
            user: this.getProfileInfo(),
        });
        this.setState({ timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss') });

        if (this.props.data.idChatRoom !== null && this.props.data.idChatRoom !== undefined) {
            await this._chatLoadRoom(this.props.data.idChatRoom);
        }

        const { updateConfigHeaderFooter, verifyNotificationCampaigns } = this.props;

        updateConfigHeaderFooter({
            prevNavStyle: 'closeShow',
            contentNavigation: this.props.dataParentObj,
            footerStyle: 'hide',
            backCloseText: 'FECHAR CONTEÚDO',
        });

        const contentNavigation = this.props.dataParentObj.contentEnrollment;
        verifyNotificationCampaigns(contentNavigation, this.props); /// Verifica ao entrar Notficação Campaingns

        if (this.props.dataParentObj.contentEnrollment) {
            const concluidedTextInfo =
                this.props.dataParentObj.contentEnrollment.status === 'COMPLETED'
                    ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}`
                    : '';

            this.setState({ concluidedText: concluidedTextInfo });
        } else if (this.props.data.hasOwnProperty('completed')) {
            const concluidedTextInfo = this.props.data.completed == 1 ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}` : '';

            this.setState({ concluidedText: concluidedTextInfo });
            this.setState({ status: this.props.data.completed === 1 ? 'COMPLETED' : '' });
        }

        this.resize();
    }

    currentTime() {
        return this.videoPlay.current.getCurrentTime() !== null ? this.videoPlay.current.getCurrentTime() : this.startedTime;
    }

    async _recordTracking(_type = null) {
        if (this.props.data.idContentEnrollment) return false;

        // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
        const timeStart = this.state.timeStart;
        const navItem = this.props.data.idContentItem;
        const navType = 1;
        const idSection = this.props.idSection || this.props.data.idSection;
        const timeExecuted = Math.floor(this.currentTime());

        // console.log(timeStart, navItem, navType, idSection, timeExecuted);
        await recordTracking(timeStart, navItem, navType, idSection, timeExecuted);
    }

    exit = async () => {
        if (
            this.props.data.idContentEnrollment &&
            this.props.dataParentObj.contentEnrollment.status !== 'COMPLETED' &&
            this.state.mediaPercentage > this.props.dataParentObj.contentEnrollment.progress &&
            this.state.mediaPercentage < 100
        ) {
            const objTrackingContent = {
                status: 'IN_PROGRESS',
                grade: 0,
                progress: this.state.mediaPercentage,
            };

            await learningContentUpdate(objTrackingContent, this.props.data.idContentEnrollment);
        }
    };

    componentWillUnmount() {
        if (this.props.dataParentObj.contentEnrollment) {
            this.exit();
        } else {
            this._recordTracking('WillUnmount');
        }
    }

    resize() {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    async _chatLoadRoom(room) {
        this.setState({ isLoading: true });
        await chatLoadRoom(room)
            .then((res) => {
                if (res && res.data && res.data.success === false) {
                    this.setState({ isLoading: false, error: true });
                    return;
                } else {
                    if (res.data.data.length > 0) {
                        this.setState({
                            messages: res.data.data[res.data.data.length - 1].messages,
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false, error: true });
            });
    }

    componentDidUpdate() {}

    getProfileInfo() {
        let token = {};
        try {
            token = jwtDecode(getToken());
        } catch {
            console.error('error on token get');
        }
        return token;
    }

    scrollToBottom = () => {
        this.msgEnd.scrollIntoView({ behavior: 'smooth' });
    };

    /* chat - end */

    render() {
        const { isVideo = false } = this.props;
        const { controls } = this.state;
        const { isDesktop } = this.state;
        // const { hasChat } = this.state;
        const data = this.props.data;

        const currentMoment = moment();
        const itemMoment = moment(data.initDate);
        const isLive = currentMoment.isSame(itemMoment, 'day');
        const saveTimeExecuted = (e) => {
            const percentage = Math.round(e.played * 100);
            this.setState({ mediaPercentage: percentage });

            this.updateExecutedTime(e.playedSeconds);
        };

        const beggining = () => {
            this.beginVideo();
        };
        const continueLastTime = () => {
            this.prepareVideo(this.props.data.lastPosition);
        };
        const onStart = () => {
            this.start();
        };

        const onEnd = async () => {
            const concluidedTextInfo = "<i class='fa fa-check' aria-hidden='true'></i> Concluído";
            this.setState({ concluidedText: concluidedTextInfo });

            if (this.props.data.idContentEnrollment && this.props.dataParentObj.contentEnrollment.status !== 'COMPLETED') {
                if (this.props.data.idContentEnrollment) {
                    const objTrackingContent = {
                        status: 'COMPLETED',
                        grade: 0,
                        progress: 100,
                    };

                    await learningContentUpdate(objTrackingContent, this.props.data.idContentEnrollment);

                    /// Verifica ao completar o Content e se o NodeErrolment foi Completado e Notificação Campaingns
                    const contentNavigation = this.props.dataParentObj.contentEnrollment;
                    this.props.verifyNotificationCampaigns(contentNavigation, this.props);
                }

                if (this.props.params.idEnrollment) {
                    const objTrackingContent = {
                        idEnrollment: this.props.params.idEnrollment,
                        type: 'ACCESS_CONTENT',
                        progress: 100,
                    };

                    await learningContentsTracking(objTrackingContent, this.props.data.idContentItem);
                }
            }

            const { updateConfigHeaderFooter } = this.props;

            updateConfigHeaderFooter({
                prevNavStyle: 'closeShow',
                footerStyle: 'hide',
                contentNavigation: this.props.dataParentObj,
                backCloseText: 'FECHAR CONTEÚDO',
                unlockNext: true,
            });

            this._recordTracking();
        };

        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;

        const isYouTubeLink = (link) => {
            return youtubeRegex.test(link);
        };

        return (
            <>
                <ModalDialog
                    btn1Info={{ event: beggining, text: 'Assistir desde o inicío?' }}
                    btn2Info={{
                        event: continueLastTime,
                        text: 'Continuar de onde parou?',
                        className: 'active',
                    }}
                    show={this.state.modal}
                />

                <div className="pctPage ptcLivePlayer player-wrapper">
                    <div className="player-wrapper-base">
                        <ReactPlayer
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            ref={this.videoPlay}
                            playing={this.state.playing}
                            className="react-player"
                            url={data.contentLocation}
                            controls={controls}
                            onProgress={saveTimeExecuted}
                            onStart={onStart}
                            onEnded={onEnd}
                            width="100%"
                            height="100%"
                        />
                    </div>

                    <div className="infos">
                        <div className="infosBase">
                            {(data.text || data.description) && (
                                <div className="mediaInfos">
                                    {data.text && <Text component="h2" text={data.text} />}
                                    {data.description && <Text component="p" text={data.description} />}
                                </div>
                            )}

                            <div className="footerInfos">
                                <div className="leftInfo">
                                    <div className="dataInfos">
                                        <div className="dis dis1">
                                            {<Label text={data.label ? data.label : 'Vídeo'} className="label" />}

                                            {!!data.duration && <Duration text={data.duration} timeFormat={'seconds'} />}

                                            <Text component="small" text={this.state.concluidedText} className="concluidedText" />

                                            {isYouTubeLink(this.props.data.contentLocation) && Boolean(this.props.data.shareable) ? (
                                                <ShareButton
                                                    className="ShareButtonCardStandard"
                                                    callEvent={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    link={this.props.data.contentLocation}
                                                    idContent={Number(this.props.params.id)}
                                                    idUser={this.state.user.idUser}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
    updateApiReplication: (item) => dispatch(updateApiReplication(item)),
});

const mapStateToProps = (store) => ({
    apiReplication: store.apiReplicationState,
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
    configCampaign: store.configCampaignState.configCampaign,
});

export const withRouterWrapper = (WrappedComponent) => (props) => {
    const params = useParams();

    const { verifyNotificationCampaigns } = useNotificationCampaigns();

    return <WrappedComponent {...props} params={params} verifyNotificationCampaigns={verifyNotificationCampaigns} />;
};

export default compose(withRouterWrapper, connect(mapStateToProps, mapDispatchToProps))(ContentVideo);
