import './style.scss';

import React, { useState, useEffect, useContext } from 'react';

import { getProfileInfo } from 'services/utils';

import { Preloader } from '../../atoms';

import { EvaluationKnowPlayer, EvaluationKnowledgeFeedback, EvaluationKnowledgeIntroduction } from '../../molecules';

import { CreateEvaluationKnowledgeContext } from '../../../pctContexts/contextEvaluationKnowledge';

type Props = {
    fetchData: any;
    idEnrollment: any;
    idEvaluation: any;
    idQuestionnaire: any;
    idNodeEnrollment: any;
    finishEvent?: any;
};
const EvaluationKnowledge = ({ fetchData, idEnrollment, idEvaluation, idQuestionnaire, idNodeEnrollment, finishEvent }: Props) => {
    const userProfile = getProfileInfo();

    const { answers, evaluation, finishDate, timeLeft, questionnaireType } = fetchData;
    const {
        setContextCurrentEvaluation,
        setContextCurrentAnswers,
        setContextGoToEvaluationScreen,
        evaluationInitializedCurrentIndex,
        setContextCurrentIdUser,
        setContextCurrentIdEnrollment,
        setContextCurrentNodeEnrollment,
        setContextCurrentIdEvaluation,
        setContextCurrentIdQuestionnaire,
        setContextQuestionnaireTimeLeft,
    } = useContext(CreateEvaluationKnowledgeContext);

    const [contextRegistration, setContextRegistration] = useState(false);
    const [evaluationKnowledgeCurrentComponent, setEvaluationKnowledgeCurrentComponent] = useState('');

    useEffect(() => {
        const parametersPreTest = { parameters: { ...evaluation.parameters, maxTries: '1' } };
        const evaluationData = questionnaireType === 'PRE_TEST' ? { ...evaluation, ...parametersPreTest } : evaluation;

        setContextCurrentEvaluation(evaluationData);
        setContextCurrentAnswers(answers);
        setContextCurrentIdUser(userProfile.idUser);
        setContextCurrentIdEnrollment(idEnrollment);
        setContextCurrentNodeEnrollment(idNodeEnrollment);
        setContextCurrentIdEvaluation(idEvaluation);
        setContextCurrentIdQuestionnaire(idQuestionnaire);
        setContextQuestionnaireTimeLeft(timeLeft);
        setContextGoToEvaluationScreen(setEvaluationKnowledgeCurrentComponent);
        setEvaluationKnowledgeCurrentComponent(getInitialComponent());
        setContextRegistration(true);
    }, []);

    const getInitialComponent = () => {
        let initialComponent: string = '';

        if (finishDate) {
            initialComponent = 'FEEDBACK';
        } else if (!evaluationInitializedCurrentIndex(answers)) {
            initialComponent = 'INTRODUCTION';
        } else {
            initialComponent = 'PLAYER';
        }

        return initialComponent;
    };

    const currentPrintComponent = () => {
        switch (evaluationKnowledgeCurrentComponent) {
            case 'INTRODUCTION':
                return <EvaluationKnowledgeIntroduction />;
            case 'PLAYER':
                return <EvaluationKnowPlayer finishEvent={finishEvent} />;
            case 'FEEDBACK':
                return <EvaluationKnowledgeFeedback />;
            default:
                return <p>Componente não encontrado.</p>;
        }
    };

    if (!contextRegistration) return <Preloader />;

    return currentPrintComponent();
};

export default EvaluationKnowledge;
