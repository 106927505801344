import './style.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import { updateHeaderStatus, updateFooterStatus, updateConfigHeaderFooter, updateConfigCampaign, updateApiReplication } from '../../../actions';

import useNotificationCampaigns from '../../../pctHooks/useNotificationCampaigns';

import { getToken, learningContentsTracking, learningContentUpdate, recordTracking } from '../../../services/api';

// import '../assets/css/CardGrid.css';
import { useHistory } from 'react-router-dom';

import { useScrolledToEdge } from 'scrolled-to-edge';

const token = getToken();

function ContentArtigo(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { data } = props;

    const { verifyNotificationCampaigns } = useNotificationCampaigns();

    const _recordTracking = async (_type: string | null = null) => {
        if (props.dataParentObj.contentEnrollment) return false;
        // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
        const timeStart = 0;
        const navItem = props.data.idContentItem;
        const navType = 1;
        const idSection = props.data.idSection;
        const timeExecuted = 0;

        // console.log(timeStart, navItem, navType, idSection, timeExecuted);
        const res = await recordTracking(timeStart, navItem, navType, idSection, timeExecuted);
    };

    const onScrollContentEnd = async () => {
        if (props.data.idContentEnrollment && props.dataParentObj.contentEnrollment.status != 'COMPLETED') {
            if (props.data.idContentEnrollment) {
                const objTrackingContent = {
                    status: 'COMPLETED',
                    grade: 0,
                    progress: 100,
                };

                await learningContentUpdate(objTrackingContent, props.data.idContentEnrollment);
            }

            if (props.data.idEnrollment) {
                const objTrackingContent = {
                    idEnrollment: props.data.idEnrollment,
                    type: 'ACCESS_CONTENT',
                    progress: 100,
                };

                await learningContentsTracking(objTrackingContent, props.data.idContentItem);
            }

            /// Verifica ao completar o Content e se o NodeErrolment foi Completado e Notificação Campaingns
            const contentNavigation = { idEnrollment: props.dataParentObj.currentItem.idEnrollment };
            verifyNotificationCampaigns(contentNavigation, props);
        }

        props.updateConfigHeaderFooter({
            logoStyle: data.text ? `hide` : 'show',
            titleHeader: data.text ? `${data.text}` : null,
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: props.dataParentObj,
            unlockNext: true,
            backCloseText: 'FECHAR CONTEÚDO',
        });

        _recordTracking();
    };

    useEffect(() => {
        const { updateConfigHeaderFooter } = props;
        updateConfigHeaderFooter({
            logoStyle: data.text ? `hide` : 'show',
            titleHeader: data.text ? `${data.text}` : null,
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: props.dataParentObj,
            unlockNext: true,
            backCloseText: 'FECHAR CONTEÚDO',
        });

        // Verificar status Notification Campaigns Inicial
        const contentNavigation = { idEnrollment: props.dataParentObj.currentItem.idEnrollment };
        verifyNotificationCampaigns(contentNavigation, props);

        if (!Boolean(data.contentLocation) && !Boolean(data.content)) {
            history.goBack();
        }

        setTimeout(() => {
            setLoading(false);
            onScrollContentEnd();
        }, 2000);

        return () => {
            _recordTracking('WillUnmount');
        };
    }, []);

    const container = useScrolledToEdge((e) => {}, 0);

    return (
        // {loading ? <Preloader /> : null}

        // <ScrolledToEdge callback={() => {console.log("FIM")}} offset={0} onChange={e => console.log(e)}>
        <div className="pctPage ptcSinglePage scrolling-container" ref={container}>
            <div id="elementBody" className="htmlContentBody">
                {data.author != '' && <div className="singlePageHeader">Autor - {Parser(data.author)}</div>}

                {Parser(data.content)}
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
    updateApiReplication: (item) => dispatch(updateApiReplication(item)),
});

const mapStateToProps = (store) => ({
    apiReplication: store.apiReplicationState,
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
    configCampaign: store.configCampaignState.configCampaign,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentArtigo);
