import './style.scss';

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { updateHasEnrollmentCampaign, updatePlan, updateFeatures } from 'actions';

import { getEnrollmentCampaignStatus, withRouterAndRef } from 'services/utils';
// import useNotificationCampaigns from 'pctHooks/useNotificationCampaigns';
import PctUpgrade from '../PctUpgrade';
import PctPlanContract from '../PctPlanContract';

import HeaderMenuPrincipal from './components/HeaderMenuPrincipal';
import HeaderContentNavigation from './components/HeaderContentNavigation';
import HeaderDropdownMenu from './components/HeaderDropdownMenu';
import HeaderSearchBox from './components/HeaderSearchBox';
import HeaderLogo from './components/HeaderLogo';
import HeadeNavControlls from './components/HeadeNavControlls';
import HeaderToast from './components/HeaderToast';
import HeaderNotification from './components/HeaderNotification';
import HeaderLesson from './components/HeaderLesson';
import { OriginNavigation } from 'pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model';
import usePlan from 'pctHooks/usePlan';
import { isAuthenticated, renewlogin } from 'services/api';

const PctHeader = (props) => {
    const { updatePlan, planUpgrade } = props;
    const { getActivePlanData, openModalUpgrade, triggerCallBackInterval } = usePlan();
    const [scrolling, setScrolling] = useState(false);
    const [homeScreenStatus, setScreenStatus] = useState(false);
    const [headerStyleContainer, headerStyleStatus] = useState({});
    // const [campaign, setCampaign] = useState(null);
    const [searchOpen, setSearchOpen] = useState(false);
    const [activePlan, setActivePlan] = useState(undefined);
    const [scoSingleContent, setScoSingleContent] = useState(null);

    const init = useCallback(async () => {
        const activePlanData = await getActivePlanData();
        setActivePlan(activePlanData);
        if (activePlanData && activePlanData.userIsAdmin) {
            triggerCallBackInterval(activePlanData, () =>
                openModalUpgrade({
                    hasRequestedUpgrade: false,
                    userIsAdmin: activePlanData.userIsAdmin,
                    numberRequest: 0,
                    originNavigation: OriginNavigation.header,
                }),
            );
        } else if (activePlanData && activePlanData.deadline <= 0) {
            triggerCallBackInterval(activePlanData, () =>
                openModalUpgrade({
                    hasRequestedUpgrade: false,
                    userIsAdmin: activePlanData.userIsAdmin,
                    numberRequest: 0,
                    originNavigation: OriginNavigation.header,
                }),
            );
        }
    }, [getActivePlanData, openModalUpgrade, triggerCallBackInterval]);

    useEffect(() => {
        const [navigationEntry] = performance.getEntriesByType('navigation');

        if (navigationEntry && navigationEntry.type !== 'reload') {
            (async () => {
                const activePlanData = await getActivePlanData();
                setActivePlan(activePlanData);
                if (activePlanData && activePlanData.trial && activePlanData.userIsAdmin) {
                    openModalUpgrade({
                        hasRequestedUpgrade: false,
                        userIsAdmin: activePlanData.userIsAdmin,
                        numberRequest: 0,
                        originNavigation: OriginNavigation.header,
                    });
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        init();

        return () => {
            setActivePlan(undefined);
        };
    }, [init, planUpgrade]);

    const utilsEnrollmentCampaignStatusApi = useCallback(() => {
        const enrollmentCampaignStatus = getEnrollmentCampaignStatus();
        enrollmentCampaignStatus.then((res) => {
            props.updateHasEnrollmentCampaign({
                userIsInCampaign: res[0].data.userIsInCampaign,
                hasEnrollmentRequests: res[1].data.hasEnrollmentRequests,
            });
            props.updateFeatures({
                externalcourses: res[2].data.active,
            });
        });
    }, [props]);

    useEffect(() => {
        props.location.pathname === '/' || props.location.pathname === '/home'
            ? setScreenStatus(true)
            : setScreenStatus(false);
        headerStyleStatus(props.configHeaderFooter);
        // setCampaign(props.configCampaign);

        if (props.configHeaderFooter.scoSingle === true) {
            setScoSingleContent(props.configHeaderFooter.contentNavigation.content);
        }
        if (props.configHeaderFooter.scoSingle === false) {
            setScoSingleContent(null);
        }
    }, [props]);

    useEffect(() => {
        utilsEnrollmentCampaignStatusApi();
        const [navigationEntry] = performance.getEntriesByType('navigation');
        if (navigationEntry && isAuthenticated()) {
            renewlogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    window.addEventListener('scroll', (e) => {
        const window = e.currentTarget;
        let currentPosition = window.scrollY;
        currentPosition > 50 ? setScrolling(true) : setScrolling(false);
    });

    //useNotificationCampaigns({ props });

    const {
        headerStyle,
        prevNavStyle,
        logoStyle,
        tituloStyle,
        perfilUsuarioStyle,
        perfilStyle,
        menuStyle,
        titleHeader,
        closeFn,
        nodeCollection,
        backCloseText,
    } = headerStyleContainer;

    return (
        <>
            {headerStyle !== 'hide' ? (
                <header
                    className={`pctOrganism pctHeader ${headerStyle} ${scrolling ? 'active' : ''} ${
                        props.configHeaderFooter.contentNavigation ? 'contentView' : ''
                    }`}
                >
                    <div className="leftHeader">
                        <HeaderMenuPrincipal
                            menuStyle={menuStyle}
                            hasEnrollmentRequests={props.configHasEnrollmentCampaign?.hasEnrollmentRequests}
                            userIsInCampaign={props.configHasEnrollmentCampaign?.userIsInCampaign}
                            externalcourses={props.configFeatures?.externalcourses}
                        />

                        <HeadeNavControlls
                            prevNavStyle={prevNavStyle}
                            closeFn={closeFn}
                            navHistory={props.history}
                            backCloseText={backCloseText}
                            nodeCollection={nodeCollection}
                            updatePlan={updatePlan}
                            planUpgrade={props.planUpgrade}
                            configHeaderFooter={props.configHeaderFooter}
                        />
                    </div>

                    <HeaderLogo
                        searchOpen={searchOpen}
                        logoStyle={logoStyle}
                        homeScreenStatus={homeScreenStatus}
                        tituloStyle={tituloStyle}
                        titleHeader={titleHeader}
                        isUpgradeButtonActive={props.planUpgrade.isUpgradeButtonActive}
                    />

                    <div className="rightHeader">
                        <div className="rigthRow">
                            {!activePlan ? (
                                <PctUpgrade
                                    hide={searchOpen}
                                    updatePlan={updatePlan}
                                    isUpgradeButtonActive={props.planUpgrade.isUpgradeButtonActive}
                                    {...props.planUpgrade}
                                />
                            ) : (
                                <PctPlanContract
                                    hide={searchOpen}
                                    {...activePlan}
                                    planUpgrade={planUpgrade}
                                    updatePlan={updatePlan}
                                />
                            )}

                            <div className={`itensR ${searchOpen && 'open'}`}>
                                <HeaderSearchBox searchOpen={searchOpen} setSearchOpen={setSearchOpen} />

                                <HeaderDropdownMenu
                                    perfilUsuarioStyle={perfilUsuarioStyle}
                                    hasEnrollmentRequests={props.configHasEnrollmentCampaign?.hasEnrollmentRequests}
                                    userIsInCampaign={props.configHasEnrollmentCampaign?.userIsInCampaign}
                                    externalcourses={props.configFeatures?.externalcourses}
                                />

                                <HeaderNotification perfilStyle={perfilStyle} />
                            </div>
                        </div>

                        <HeaderContentNavigation
                            configHeaderFooter={props.configHeaderFooter}
                            scoSingleContent={scoSingleContent}
                        />

                        <HeaderLesson lessonHeader={props.configHeaderFooter.lessonHeader} />
                    </div>
                </header>
            ) : null}

            <HeaderToast campaign={props.configCampaign} />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateHasEnrollmentCampaign: (item) => dispatch(updateHasEnrollmentCampaign(item)),
    updatePlan: (item) => dispatch(updatePlan(item)),
    updateFeatures: (item) => dispatch(updateFeatures(item)),
});

const mapStateToProps = (store) => ({
    currentContentType: store.contentState.currentContentType,
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
    configCampaign: store.configCampaignState.configCampaign,
    configHasEnrollmentCampaign: store.hasEnrollmentCampaignState.configHasEnrollmentCampaign,
    planUpgrade: store.planUpgrade,
    apiReplication: store.apiReplicationState,
    configFeatures: store.configFeaturesState.configFeatures,
});

export default withRouterAndRef(connect(mapStateToProps, mapDispatchToProps)(PctHeader));
