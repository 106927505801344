import './style.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
import { updateHeaderStatus, updateFooterStatus, updateConfigHeaderFooter, updateConfigCampaign, updateApiReplication } from '../../../actions';
import { getToken, learningContentsTracking, learningContentUpdate, recordTracking } from '../../../services/api';

import { useHistory } from 'react-router-dom';
import Preloader from '../../atoms/Preloader';

import useNotificationCampaigns from '../../../pctHooks/useNotificationCampaigns';

const token = getToken();

function ContentHtml(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { data, dataParentObj, idSection } = props;

    const _recordTracking = async (_type: string | null = null) => {
        if (props.data.idContentEnrollment) return false;

        // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
        const timeStart = 0;
        const navItem = props.data.idContentItem;
        const navType = 1;
        const idSection = props.data.idSection;
        const timeExecuted = 0;

        // console.log(timeStart, navItem, navType, idSection, timeExecuted);
        const res = await recordTracking(timeStart, navItem, navType, idSection, timeExecuted);
    };

    const { verifyNotificationCampaigns } = useNotificationCampaigns();

    useEffect(() => {
        const { updateConfigHeaderFooter } = props;

        updateConfigHeaderFooter({
            logoStyle: data.text ? `hide` : 'show',
            titleHeader: data.text ? `${data.text}` : null,
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: dataParentObj,
            backCloseText: 'FECHAR CONTEÚDO',
        });

        console.log(data);

        if (!Boolean(data.contentLocation) && !Boolean(data.content)) {
            history.goBack();
        }

        verifyNotificationCampaigns({ idEnrollment: dataParentObj.currentItem.idEnrollment }, props); // Verificar status Notification Campaigns Inicial
    }, []);

    const enrollmentTracking = async () => {
        if (props.data.idContentEnrollment && props.dataParentObj.contentEnrollment.status != 'COMPLETED') {
            if (props.data.idContentEnrollment) {
                const objTrackingContent = {
                    status: 'COMPLETED',
                    grade: 0,
                    progress: 100,
                };

                await learningContentUpdate(objTrackingContent, props.data.idContentEnrollment);
            }

            if (props.data.idEnrollment) {
                const objTrackingContent = {
                    idEnrollment: props.data.idEnrollment,
                    type: 'ACCESS_CONTENT',
                    progress: 100,
                };

                await learningContentsTracking(objTrackingContent, props.data.idContentItem);
            }

            /// Verifica ao completar o Content e se o NodeErrolment foi Completado e Notificação Campaingns
            const contentNavigation = { idEnrollment: props.dataParentObj.currentItem.idEnrollment };
            verifyNotificationCampaigns(contentNavigation, props);

            props.updateConfigHeaderFooter({
                logoStyle: data.text ? `hide` : 'show',
                titleHeader: data.text ? `${data.text}` : null,
                prevNavStyle: 'closeShow',
                footerStyle: 'hide',
                contentNavigation: dataParentObj,
                backCloseText: 'FECHAR CONTEÚDO',
            });
        }
    };

    return (
        <>
            {loading ? <Preloader /> : null}
            {/* {
        setTimeout(() => {
          setLoading(false);
        }, 3000)
      } */}
            <div className="pctPage ptcContentHtml">
                {data && data.contentLocation && (
                    <Iframe
                        url={`${data.contentLocation}?token=${token}`}
                        height="100%"
                        width="100%"
                        onLoad={() => {
                            setLoading(false);
                            _recordTracking();
                            enrollmentTracking();
                        }}
                        allowFullScreen
                    />
                )}
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
    updateApiReplication: (item) => dispatch(updateApiReplication(item)),
});

const mapStateToProps = (store) => ({
    apiReplication: store.apiReplicationState,
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
    configCampaign: store.configCampaignState.configCampaign,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentHtml);
