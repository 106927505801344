import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Avatar, Text } from 'pctComponents/atoms';
import ModalDialog from 'pctComponents/modals/ModalDialog';

import { getAppDetails, getProfileInfo } from 'services/utils';

import { logout, logoutSSO } from 'services/api';
import NiceModal from '@ebay/nice-modal-react';
import { cloneModalAccessSupport } from 'pctComponents/modals/ModalSupport';
import { FaCheck } from 'react-icons/fa';
import { exitSupportAccess } from 'pctComponents/molecules/SupportAccess';

function HeaderDropdownMenu({ perfilUsuarioStyle, hasEnrollmentRequests, userIsInCampaign, externalcourses }) {
    const history = useHistory();
    const [drownOpen, setDrownOpen] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const appDetails = JSON.parse(localStorage.getItem('appDetails'));
    const [loadingAccessSupport, setLoadingAccessSupport] = useState(false);

    const logoutEvent = () => {
        setLogoutModal(true);
    };

    const logoutYes = () => {
        logout();

        const samlLogoutUrl = getAppDetails().samlConfiguration?.logoutUrl;

        if (samlLogoutUrl) {
            logoutSSO(samlLogoutUrl);
        } else {
            history.push('/login');
        }
    };

    const logoutNo = () => {
        setLogoutModal(false);
    };

    const menuDataInfo = localStorage.getItem('menu-data') ? JSON.parse(localStorage.getItem('menu-data')) : null;

    // const userIsAdmin = () => {
    //     let isAdmin = false;

    //     if (menuDataInfo) {
    //         if (menuDataInfo.loginInfo.admin === 1 || menuDataInfo.loginInfo.admin === 2) {
    //             isAdmin = true;
    //         }
    //     }

    //     return isAdmin;
    // };

    const userCanAccessSupport = () => {
        return Boolean(menuDataInfo?.loginInfo?.canAccessSupport);
    };

    const getAdmLink = () => {
        let linkAdm = '';

        if (!menuDataInfo) {
            linkAdm = `https://${appDetails.adminDomain}`;
        } else {
            linkAdm = `https://${appDetails.adminDomain}?token=${menuDataInfo.token}`;
        }

        return linkAdm;
    };

    const redictToPerfil = () => {
        history.push(`/editPerfil`);
    };

    const redictToMyJourney = (tab = '') => {
        history.push(`/myjourney/${tab}`);
    };

    const listApps =
        menuDataInfo?.loginInfo?.appsUserAccess?.length > 1 ? menuDataInfo?.loginInfo?.appsUserAccess : null;

    const isActiveApp = (app) => {
        let { idApp, idCompany } = getProfileInfo();
        return Boolean(app.idApp === idApp && app.idCompany === idCompany);
    };

    const clickRedirectLogin = (app) => {
        if (isActiveApp(app)) {
            return;
        }
        window.open(`https://${app.domain}/#/login?token=${app?.token}`);
    };

    const showModal = () => {
        NiceModal.show(cloneModalAccessSupport(), { adminProps: {} });
    };

    if (loadingAccessSupport) {
        return <Redirect to="/" />;
    }

    const inAccessSupport = Boolean(menuDataInfo?.loginInfo?.accessSupport?.isActive);

    return (
        <>
            <DropdownMenu.Root
                className={'DropdownMenuContentBase'}
                onOpenChange={(e) => {
                    setDrownOpen(e);
                }}
            >
                <DropdownMenu.Trigger asChild>
                    <button className={`perfilUsuarioHeader ${perfilUsuarioStyle}`}>
                        <Avatar className="avatarHrader" seta={drownOpen} />
                    </button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                        <DropdownMenu.Item
                            className="DropdownMenuItem"
                            onClick={() => {
                                redictToPerfil();
                            }}
                        >
                            Meu perfil
                        </DropdownMenu.Item>

                        <DropdownMenu.Item
                            className="DropdownMenuItem"
                            onClick={() => {
                                redictToMyJourney();
                            }}
                        >
                            Minha jornada
                        </DropdownMenu.Item>

                        <DropdownMenu.Item
                            className="DropdownMenuItem"
                            onClick={() => {
                                redictToMyJourney('historic');
                            }}
                        >
                            Meu histórico
                        </DropdownMenu.Item>

                        {hasEnrollmentRequests && (
                            <DropdownMenu.Item
                                className="DropdownMenuItem"
                                onClick={() => {
                                    redictToMyJourney('requestEnrollmentStatus');
                                }}
                            >
                                Solicitações de matrícula
                            </DropdownMenu.Item>
                        )}

                        <DropdownMenu.Item
                            className="DropdownMenuItem"
                            onClick={() => {
                                redictToMyJourney('certificates');
                            }}
                        >
                            Meus certificados
                        </DropdownMenu.Item>

                        {userIsInCampaign && (
                            <DropdownMenu.Item
                                className="DropdownMenuItem"
                                onClick={() => {
                                    redictToMyJourney('gamification');
                                }}
                            >
                                Campanhas e Gamificação
                            </DropdownMenu.Item>
                        )}

                        {externalcourses && (
                            <DropdownMenu.Item
                                className="DropdownMenuItem"
                                onClick={() => {
                                    redictToMyJourney('externalCourse');
                                }}
                            >
                                Cursos externos
                            </DropdownMenu.Item>
                        )}

                        {
                            <>
                                <DropdownMenu.Item className="DropdownMenuItem" disabled={inAccessSupport}>
                                    <>
                                        {!inAccessSupport && (
                                            <a target="_blank" rel="noopener noreferrer" href={getAdmLink()}>
                                                Administração
                                            </a>
                                        )}
                                        {inAccessSupport && <span className="disabled-item">Administração</span>}
                                    </>
                                </DropdownMenu.Item>

                                {userCanAccessSupport() && !inAccessSupport && (
                                    <DropdownMenu.Item className="DropdownMenuItem" onClick={showModal}>
                                        Acesso suporte
                                    </DropdownMenu.Item>
                                )}
                            </>
                        }
                        {inAccessSupport && (
                            <DropdownMenu.Item
                                className="DropdownMenuItem"
                                onClick={() => exitSupportAccess({ setLoadingAccessSupport })}
                            >
                                Sair do acesso suporte
                            </DropdownMenu.Item>
                        )}
                        {listApps && (
                            <div className="myAppList">
                                <Text component={'small'} text={'Meus Aplicativos'} />
                                <ul>
                                    {listApps.map((app, key) => (
                                        <li
                                            key={key}
                                            className={`${
                                                isActiveApp(app) ? 'active' : inAccessSupport ? 'disabled-item' : ''
                                            }`}
                                        >
                                            <div
                                                className="row-company"
                                                onClick={() => (inAccessSupport ? () => {} : clickRedirectLogin(app))}
                                            >
                                                <span className="containerIconApp">
                                                    <img src={app.favIcon} alt="" />
                                                </span>
                                                <Text
                                                    component={'p'}
                                                    text={`${app.appName} | ${app.companyName}`}
                                                    tooltip={Boolean(app.appName.length > 25)}
                                                    limit={25}
                                                />
                                                {isActiveApp(app) && <FaCheck />}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <DropdownMenu.Item
                            className="DropdownMenuItem"
                            onClick={() => {
                                logoutEvent();
                            }}
                        >
                            Sair
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>

            <ModalDialog
                title={'Tem certeza de que deseja sair?'}
                btn1Info={{ event: logoutNo, text: 'Cancelar' }}
                btn2Info={{ event: logoutYes, text: 'Sim', className: 'active' }}
                show={logoutModal}
                setShow={setLogoutModal}
            />
        </>
    );
}

export default HeaderDropdownMenu;
